<template>
  <transition name="modal">
    <b-card class="modal-container">
      <ValidationObserver ref="observermodifauteur">
      <form @submit.prevent="emitModifierAuteur" id="modifSource">
        <div class="titreModal pt-2 pb-2">
          <h5>Modifier l'auteur sélectionné</h5>
        </div>
        <div>
          <b-card class=" formModal mr-4 ml-4">
            <b-form-group class="labelTitre" id="input-group-1" label-size="sm" label="Nom de l'auteur:" label-for="input-horizontal">
              <b-form-input id="input-1" v-model="nom" type="text" size="sm">
              </b-form-input>
            </b-form-group>
            <b-form-group class="labelTitre" id="input-group-2" label-size="sm" label="Prénom de l'auteur:" label-for="input-2">
              <b-form-input id="input-2" v-model="prenom" type="text" size="sm">
              </b-form-input>
            </b-form-group>

            <ValidationProvider name="Email" rules="email" v-slot="{errors}">
            <b-form-group class="labelTitre" id="input-group-5" label-size="sm" label="Mail de l'auteur:" label-for="input-6">
              <b-form-input id="input-5" v-model="email" type="text" size="sm">
              </b-form-input>
              <span style="color:red;">{{errors[0]}}</span>
            </b-form-group>
            </ValidationProvider>

            <b-form-group class="labelTitre" id="input-group-8" label="Organisme:" label-size="sm" label-for="input-8">
              <b-input-group>
                <b-form-input id="input-6" v-model="organismeSearch"
                              @input="updateSuggestions"
                              placeholder="Rechercher un organisme" type="text" size="sm">
                </b-form-input>
                <b-input-group-append>
                  <b-button @click="clearOrganismeSearch" variant="outline-danger" size="sm">Effacer</b-button>
                </b-input-group-append>
              </b-input-group>

              <div class="d-flex justify-content-center align-items-center h-100">
                <div class="suggestions" v-if="suggestions.length">
                  <b-table sticky-header
                           striped bordered small selectable
                           :items="suggestions"
                           :fields="fields"
                           :per-page="perPage"
                           :current-page="currentPage"
                           @row-clicked="onRowClicked">
                  </b-table>
                  <b-pagination v-model="currentPage" :total-rows="suggestions.length" :per-page="perPage"></b-pagination>
                </div>
              </div>
            </b-form-group>
          </b-card>
        </div>
        <div class="bouton">
          <b-button pill type="submit" class="valid m-2">Valider</b-button>
          <b-button pill class="closed m-2" @click="$emit('close')" id="close">Annuler</b-button>
        </div>
      </form>
      </ValidationObserver>
    </b-card>
  </transition>
</template>

<script>
import http from '@/http.js'
import eventBus from '@/eventBus.js'
import {extend} from "vee-validate";
import {email} from "vee-validate/dist/rules";

extend('email', {
  ...email,
  message: "Le format de l'email saisi est incorrect"
});
export default {
  name:"ModalModifAuteur",

  props:{
    id:{
      type:Number
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  destroyed(){
    eventBus.$off('modifierAuteur');
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data(){
    return {
      locale:"fr-FR",
      idAuteur:'',
      identifiantAuteur: this.id,
      nom : '',
      prenom:'',
      email : '',
      organismes:[],
      organisme:{},
      auteurData: {},
      organismeSelectionne: null,
      organismeSearch: '',
      suggestions: [],
      currentPage: 1,
      perPage: 7,
      fields: [
        {key: 'sigle', label:'Sigle', class: 'text-center'},
        {key: 'nom', label:'Nom', class: 'text-center'},
      ],
      items: [
        { key: "sigle", label: "Sigle", label_long: "Sigle:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "nom", label: "Nom", label_long: "Nom:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
      ],
    };
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  created(){

    http.getOrganismesBySigle().then((data)=>{
      this.organismes = data;
    });

    if(this.id !== undefined){
      http.loadAuteur(this.id).then((data)=>{
        this.idAuteur = data.idAuteur;
        this.nom = data.nom;
        this.prenom = data.prenom;
        this.email = data.email;
        if(data.organisme !== null){
          this.organismeSearch = `${data.organisme.sigle} (${data.organisme.nom})`;
          this.organismeSelectionne = data.organisme;
        }
      })

    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  methods:{
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    updateSuggestions() {
      this.suggestions = this.organismes.filter(org =>
          org.sigle.toLowerCase().includes(this.organismeSearch.toLowerCase()) ||
          org.nom.toLowerCase().includes(this.organismeSearch.toLowerCase())
      );
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    selectOrganisme(org) {
      this.organismeSelectionne = org;
      this.organismeSearch = `${org.sigle} (${org.nom})`;
      this.suggestions = [];
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    onRowClicked(item) {
      this.selectOrganisme(item);
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    clearOrganismeSearch() {
      this.organismeSearch = '';
      this.suggestions = [];
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async emitModifierAuteur(){
      const isValid = await this.$refs.observermodifauteur.validate();
      if(isValid) {
        eventBus.$emit("modifierAuteur");
      }else{
        this.invalid = true;
      }
    },
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted(){

    eventBus.$on('modifierAuteur', () =>{
      this.auteurData = {
        idAuteur : this.idAuteur,
        nom : this.nom,
        prenom : this.prenom,
        email: this.email,
        organisme: this.organismeSelectionne,
      };
      http.updateAuteur(this.auteurData).then((data) => {
        this.identifiantAuteur = data
      }).then(() => {
        this.$emit('close');
        eventBus.$emit("reloadAuteurs");
      }).finally(()=>{
        this.$bvToast.toast( `L'auteur' ${this.auteurData.idAuteur} a été modifié avec succès`,{
          variant: "success",
          autoHideDelay: 5000,
          toaster: "b-toaster-top-center",
          noCloseButton: true,
          solid: true
        });
      })
    });
  }
}
</script>

<style scoped>
.suggestions {
  position: relative;
  margin-top: 1em;
  width: 80%;
  background-color: #f8f9fa;
  font-size: 12px;
}
</style>