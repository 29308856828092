<template>
  <div class="donneesList">
    <div v-if="showDonnees">
      <p style="padding-top: 10px"><strong>II. Type(s) de données utilisées dans l'étape de traitement</strong></p>
      <div class="tableElement">
        <b-table sticky-header
               striped bordered small selectable
               :items="donnees"
               :fields="fields"
               primary-key="idDonnee"
               :select-mode="selectMode"
               :per-page="perPage"
               :current-page="currentPage"
               :filter="filtre_donnees"
               :filter-included-fields="['type','sousType','echelleGeo','echelleTemp']"
               ref="selectableTable"
               @row-clicked="onRowSelected"
               id="tableDonnees">

          <template #cell(selection)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
            </template>
          </template>
          <template #cell(idDonnee)="data">
            {{data.item.idDonnee}}
          </template>
          <template #cell(donneeSol)="data">
            <b-form-checkbox v-model="data.value" disabled></b-form-checkbox>
          </template>
        </b-table>
      </div>
      <b-row>
        <b-col cols="1" class="icon">
          <a class="icon" title="ajouter une nouvelle donnée" v-b-modal.modal-lg ="'my-modal15'"
            @click.prevent ="showModalAdd = !showModalAdd; showModalModif=false" align-self="center"><i class="fas fa-plus-circle" id="ajoutDonnee"></i></a>
        </b-col>
        <b-col cols="1" class="icon">
          <a class="icon" v-if="idDonnee!==null" title="modifier la donnée sélectionnée" v-b-modal.modal-lg ="'my-modal16'"
            @click.prevent ="showModalModif = !showModalModif; showModalAdd=false" align-self="center"><i class="fa-solid fa-pen-to-square" id="modifDonnee"></i></a>
          <a class="icon" v-else title="modifier la donnée sélectionnée" @click="selection" align-self="center"><i class="fa-solid fa-pen-to-square" id="modifDonnee"></i></a>
        </b-col>
        <b-col cols="1" class="icon">
          <a v-if="idDonnee!==null" class="icon" title="supprimer la donnée sélectionnée" v-on:click = "deleteDonnee"
            align-self="center"><i class="fa-solid fa-trash-can" id="deleteDonnee"></i></a>
          <a v-else class="icon" title="supprimer la donnée sélectionnée" v-on:click = "selection"
            align-self="center"><i class="fa-solid fa-trash-can" id="deleteDonnee"></i></a>
        </b-col>
        <b-col cols="3" class="icon">
          <b-form-group label-for="filter-input" label-align-sm="right" label-size="sm">
            <b-input-group size="sm">
              <b-form-input id="filter-input" v-model="filtre_donnees" type="search" placeholder="Rechercher Donnée"></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filtre_donnees" @click="filtre_donnees = ''"><i class="fa-solid fa-arrows-rotate"></i></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <div class="pagination">
            <p>{{nbResult}} donnée(s) sur {{rows}}</p>
          </div>
          <div class="pagination">
            <b-pagination class="customPagination"
                        v-model="currentPage"
                        :total-rows="nbResult !== 0 ? nbResult : rows"
                        :per-page="perPage"
                        aria-controls="my-card"
                        size="sm"
                        align="right"
                        id="paginationDonnee">
            </b-pagination>
          </div>
        </b-col>
      </b-row>
      <div id="my-modal15" size="xl" v-if="showModalAdd">
        <ModalAdd @close="showModalAdd = false"></ModalAdd>
      </div>
      <div id="my-modal16" size="xl" v-if="showModalModif">
        <ModalModif :id="idDonnee" @close="showModalModif = false"></ModalModif>
      </div>
    </div>
    <div v-if="donneesEtape.length!==0" class="donneesEtape mt-3">
      <b-card class="card-list">
        <p style="color: white"><strong><u>Données sélectionnées pour l'étape de traitement: {{etape.noEtape }} {{etape.nom}}</u></strong></p>
        <DonneesEtape :donneesEtape="donneesEtape"
        @deleteDonnee="handleDeleteDonnee">
        </DonneesEtape>
      </b-card>
      <div v-if="showDonnees" class="bouton">
        <b-button size="sm" v-on:click = "insertDonneesFromEtape(donneesEtape)" class="btn-ajout" id="addDonneeEtape">Enregistrer la séléction</b-button>
        <b-button size="sm" class="btn-ajout" @click="clearTable">Réinitialiser</b-button>
        <b-button size="sm" class="btn-ajout" @click="showModal" id="showRef">Passer directement aux références bibliographiques</b-button>
      </div>
    </div>
    <div class="bouton" v-else>
      <b-button v-if="showDonnees" size="sm" @click="showModal" class="btn-ajout" id="showRef">Passer directement aux références bibliographiques</b-button>
    </div>

    <div v-if="showModalRefs" class="ref mt-3" id="refMethode">
      <ModalRef
        :refsBiblios="refsBiblios"
        :idEtape="idEtape"
        :showModal="showModal">
      </ModalRef>
    </div>
  </div>
</template>
<script>


import http from "@/http";
import eventBus from '@/eventBus.js'
import DonneesEtape from '@/components/Methode/Etape/Donnees/TypesDonneesAppList.vue'
import ModalAdd from "@/components/Methode/Etape/Donnees/ModalAjouterTypesDonnees.vue"
import ModalModif from "@/components/Methode/Etape/Donnees/ModalModifierTypesDonnees.vue"
import ModalRef from "@/components/Methode/Etape/Ref/RefBiblioForMethodeList.vue";


export default {
  name: "DoonnesList",
  components: {ModalRef, DonneesEtape, ModalAdd, ModalModif},

  props:{
    donnees:{
      type:Array,
      required:true
    },
    idEtape:{
      type:Number,
      required: true
    },
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data(){
    return{
      donnee:{},
      donneesEtape: [],
      perPage: 10,
      currentPage: 1,
      selectMode: 'multi',
      showModalAdd:false,
      showModalModif:false,
      idDonnee: null,
      filtre_donnees:"",
      nbResult:0,
      localDonnees: [],
      fields: [
        {key: 'selection', label:'Sélection', class: 'text-center'},
        {key: 'idDonnee', label:'Id', class: 'text-center'},
        {key: 'type', label:'Type de donnée', class: 'text-center'},
        {key: 'sousType', label:'Sous Type', class: 'text-center'},
        {key: 'echelleGeo', label:'Echelle géographique', class: 'text-center'},
        {key: 'echelleTemp', label:'Echelle temporelle', class: 'text-center'},
        {key: 'donneeSol', label:'Donnée sol', class: 'text-center'}],
      items: [
        { key: "idDonnee", label: "idDonnee", sortable: true, tdClass: "text-left", thClass: "text-left" },
        { key: "type", label: "Type de donnée", label_long: "Type de donnée:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "sousType", label: "Sous Type", label_long: "Sous Type:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "echelleGeo", label: "Echelle géographique", label_long: "Echelle géographique:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "echelleTemp", label: "Echelle temporelle", label_long: "Echelle temporelle:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "donneeSol", label: "Donnée sol", label_long: "Donnée sol:", sortable: true, tdClass: "text-left", thClass: "text-left", type:"boolean", required: true },
      ],
      refsBiblios:[],
      refsBiblioData:{},
      showModalRefs:false,
      showDonnees:true,
      etape:{},
      idEtp: this.idEtape
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  created(){
    this.getRefs();
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted(){
    eventBus.$on("reloadRefs",() => {
      this.getRefs();
    })
    this.getRefs();
    this.getDonneesEtape();
    if(this.idEtape !==0){
      this.loadEtape();
    }

  },

  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    updateDonneess() {
      if (!this.filtre_donnees) {
        this.nbResult = this.donnees.length;
        return;
      }
      const filteredDonnees = this.donnees.filter(donnee =>
          (donnee.type && donnee.type.toLowerCase().includes(this.filtre_donnees.toLowerCase())) ||
          (donnee.sousType && donnee.sousType.toLowerCase().includes(this.filtre_donnees.toLowerCase())) ||
          (donnee.echelleGeo && donnee.echelleGeo.toLowerCase().includes(this.filtre_donnees.toLowerCase())) ||
          (donnee.echelleTemp && donnee.echelleTemp.toLowerCase().includes(this.filtre_donnees.toLowerCase()))
      );
      this.localDonnees = filteredDonnees;
      this.nbResult = filteredDonnees.length;
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    handleDeleteDonnee(donnee) {
      this.donneesEtape = this.donneesEtape.filter(d => d.idDonnee !== donnee.idDonnee);
      this.$nextTick(() => {
        this.clearSelected();
      });
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getRefs(){
      http.getRefs().then((data)=>{
        this.refsBiblios = data;
      })
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    loadEtape(){
        http.loadEtape(this.idEtape).then((data)=>{
          this.etape = data;
        })
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getDonneesEtape(){
      http.getDonneesFromEtape(this.idEtape).then((data)=>{
        this.donneesEtape = data;
      })
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    clearTable(){
      this.donneesEtape=[];
      this.clearSelected();
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    onRowSelected(item) {
      const index = this.donneesEtape.findIndex((ref) => ref.idDonnee === item.idDonnee);
      if (index !== -1) {
        this.donneesEtape.splice(index, 1);
        if (this.donneesEtape.length === 0) {
          this.idDonnee = null;
        } else if (this.idDonnee === item.idDonnee) {
          this.idDonnee = this.donneesEtape[this.donneesEtape.length - 1].idDonnee;
        }
      } else {
        eventBus.$emit('updateDonneeSelected', item.idDonnee);
        this.idDonnee = item.idDonnee;
        const doublon = this.donneesEtape.some((donnee) => {
          return donnee.idDonnee === item.idDonnee
        })
        if (!doublon) {
          this.donneesEtape.push(item);
        }
      }
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    selection(){
      this.$bvToast.toast( `Veuillez sélectionner une donnée`, {
        variant: "danger",
        autoHideDelay: 5000,
        toaster: "b-toaster-top-center",
        noCloseButton: true,
        solid: true
      });
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    showModal(){
      this.showDonnees =! this.showDonnees;
      this.showModalRefs =! this.showModalRefs;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    insertDonneesFromEtape(donneesEtape){
      if(this.idEtp !== undefined){
        http.deleteDonneesFromEtape(this.idEtp).then((data)=>{
          this.idEtp = data;
        }).then(()=> {
          return Promise.all(donneesEtape.map(donnee => http.insertDonneesFromEtape(this.idEtape, donnee.idDonnee)));
        }).then(()=>{
          this.showDonnees = false;
          this.showModalRefs = true;
        }).finally(()=>{
          this.$bvToast.toast( `Données ajoutée(s) à l'étape avec succès`,{
            variant: "success",
            autoHideDelay: 5000,
            toaster: "b-toaster-top-center",
            noCloseButton: true,
            solid: true
          });
        })
      }else{
        this.$bvToast.toast( ` Veuillez saisir une étape`, {
          variant: "danger",
          autoHideDelay: 5000,
          toaster: "b-toaster-top-center",
          noCloseButton: true,
          solid: true
        });
      }
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    deleteDonnee(){
      http.verifLEtapeDonneeExist(this.idDonnee).then((exists) =>{
        if (exists) {
          // La donnée existe dans la table lEtapeDonnee, afficher un message d'erreur
          this.$bvToast.toast(`Impossible de supprimer la donnée ${this.idDonnee} car elle est associée à des etapes.`, {
            variant: "danger",
            autoHideDelay: 5000,
            toaster: "b-toaster-top-center",
            noCloseButton: true,
            solid: true
          });
        } else {
          if (confirm(`Voulez vous vraiment supprimer la donnee : ${this.idDonnee}`))
            http.deleteDonnee(this.idDonnee).then((data) => {
              this.idDonnee = data;
            }).then(() => {
              eventBus.$emit("reloadDonnees");
            }).finally(() => {
              this.$bvToast.toast(`Donnée ${this.idDonnee} supprimée avec succès`, {
                variant: "success",
                autoHideDelay: 5000,
                toaster: "b-toaster-top-center",
                noCloseButton: true,
                solid: true
              });
            });
        }

      });
    },
},

  watch: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    filtre_donnees(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1;
        this.updateDonneess();
      }
    },
  },

  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    rows() {
      return this.donnees.length
    },
  },
}
</script>
<style scoped>

.donneesEtape{
  text-align: center;
  justify-content: center;
}
</style>