<template>
  <div class="etapeList">
    <div class="tableSecondElement">
      <b-table sticky-header
               striped bordered small
               :items="etapesMeth"
               :fields="fields"
               primary-key="idEtape"
               id="tableEtape">

        <template #cell(update)="data">
          <b-button type="button" size="sm" @click="showModal(data.item)" v-b-modal.modal-lg ="'my-modal20'" class="btn-update"><i class="fa-solid fa-pen-to-square" id="modifEtape"></i></b-button>
        </template>
        <template #cell(delete)="data">
          <b-button type="button" size="sm" @click="deleteEtape(data.item)" class="btn-delete"><i class="fas fa-trash-alt" id="deleteEtape"></i></b-button>
        </template>
      </b-table>

    </div>
    <div id="my-modal20">
      <form>
        <ModalModif :id="idEtape" :idApp="idApp" @close="showModalModif = false" v-if="showModalModif">
        </ModalModif>
      </form>
    </div>
  </div>
</template>

<script>
import http from "@/http";
import eventBus from '@/eventBus.js'
import ModalModif from "@/components/Methode/Etape/ModalModifierEtape.vue"

export default {
  name: "EtapesList",
  components: {ModalModif},

  props:{
    etapesMeth:{
      type:Array,
      required:true
    },
    idApp:{
      type:Number,
      required:true
    },
    lectureSeule:{
      type:Boolean,
      default:true
    },
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data(){
    return{
      showModalModif:false,
      idEtape: null,
      fields: [
        {key: 'noEtape', label:'Numéro', class: 'text-center'},
        {key: 'nom', label:'Nom', class: 'text-center'},
        {key: 'update', label:'Modifier', class: 'text-center'},
        {key: 'delete', label:'Supprimer', class: 'text-center'},
      ],
      items: [
        { key: "noEtape", label: "Numéro", sortable: true, tdClass: "text-left", thClass: "text-left" },
        { key: "nom", label: "Nom", label_long: "Nom:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
      ],
    }
  },
  methods: {

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    showModal(item){
      this.idEtape = item.idEtape;
      this.showModalModif =! this.showModalModif;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    deleteEtape(item){
      if(confirm(`Voulez vous vraiment supprimer l'etape : ${item.noEtape}  "${item.nom}"`))
        http.deleteEtape(item.idEtape).then(()=>{
        //
        }).then(()=>{
          eventBus.$emit("reloadEtapesMeth", this.idApp);
        }).finally(()=>{
          this.$bvToast.toast(`Etape ${item.noEtape} supprimée avec succès`, {
            variant: "success",
            autoHideDelay: 5000,
            toaster: "b-toaster-top-center",
            noCloseButton: true,
            solid: true
          })
        })
    }
  }
}
</script>

<style scoped>
.btn-delete{
  color: white !important;
  font-size: 10px;
  text-align: right;
  margin:auto;
  background-color: #ed6e6c!important;
}
.btn-delete:hover{
  color: black !important;
}
.btn-update{
  color: white !important;
  font-size: 10px;
  text-align: right;
  margin:auto;
  background-color: #275662!important;
}
.btn-update:hover{
  color: black !important;
}

</style>