<template>
  <div class="auteurList">
    <div v-if="showTable" class="tableSecondElement">
      <b-table sticky-header
               striped bordered small selectable
               :items="auteurs"
               :fields="fields"
               primary-key="idAuteur"
               :select-mode="selectMode"
               :per-page="perPage"
               :current-page="currentPage"
               :filter="filtre_auteurs"
               :filter-included-fields="['nom','prenom','email','organisme']"
               ref="selectableTable"
               @row-clicked="onRowSelected">

        <template #cell(selection)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
          </template>
        </template>
        <template #cell(idAuteur)="data">
          {{data.item.idAuteur}}
        </template>
      </b-table>
    </div>
    <b-row v-if="showTable">
      <b-col cols="5" class="icon">
        <b-form-group label-for="filter-input" label-align-sm="right" label-size="sm">
          <b-input-group size="sm">
            <b-form-input id="filter-input" v-model="filtre_auteurs" type="search" placeholder="Rechercher Auteur"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filtre_auteurs" @click="filtre_auteurs = ''"><i class="fa-solid fa-arrows-rotate"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <div class="pagination">
          <p>{{nbResult}} auteurs(s) sur {{rows}}</p>
        </div>
        <div class="pagination">
          <b-pagination class="customPagination"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        aria-controls="my-card"
                        size="sm"
                        align="right">
          </b-pagination>
        </div>
      </b-col>
    </b-row>
    <div v-if="auteursRef.length!==0" class="auteursApp mt-3">
      <b-card class="card-list">
        <p style="color: white"><strong><u>Affectation des auteurs d'une référence bibliographique</u></strong></p>
        <AuteurRef :auteursApp="auteursRef"
        @deleteAuteur="supprimerAuteur"></AuteurRef>
      </b-card>
      <div class="bouton">
        <b-button size="sm" v-on:click="AuteursFromRef(auteursRef); showTable=false" class="btn-ajout">Enregistrer la séléction</b-button>
      </div>
    </div>
  </div>
</template>
<script>

import AuteurRef from "@/components/Applications/Auteur/AuteurListFrom.vue";
import eventBus from '@/eventBus.js'
import http from "@/http";

export default {
  name: "AuteursList",
  components: {AuteurRef},

  props:{
    auteurs:{
      type:Array,
      required:true
    },
    auteursRef:{
      type:Array,
      required:true
    },
    lectureSeule:{
      type:Boolean,
      default:true
    },
    AuteursFromRef: Function

  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data(){
    return{
      auteur:{},
      perPage: 10,
      currentPage: 1,
      selectMode: 'multi',
      showModalAdd:false,
      showModalModif:false,
      showTable:true,
      idAuteur:'',
      filtre_auteurs:"",
      localAuteurs: [],
      nbResult:0,

      fields: [
        {key: 'selection', label:'Sélection', class: 'text-center'},
        {key: 'idAuteur', label:'Id', class: 'text-center'},
        {key: 'nom', label:'Nom', class: 'text-center'},
        {key: 'prenom', label:'Prénom', class: 'text-center'},
        {key: 'email', label:'Mail', class: 'text-center'},
        {key: 'organisme', label:'Organisme', class: 'text-center'}],
      items: [
        { key: "idAuteur", label: "idAuteur", sortable: true, tdClass: "text-left", thClass: "text-left" },
        { key: "nom", label: "Nom", label_long: "Nom:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "prenom", label: "Prénom", label_long: "Prénom:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "email", label: "mail", label_long: "Email:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "organisme", label: "organisme", label_long: "Organisme:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },

      ],
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  created(){
    if(this.idRef !== undefined){
      http.getAuteursFromRef(this.idRef).then((data)=>{
        this.auteursRef = data;
      });
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted(){
    eventBus.$on('updateRefSelected',(idRef)=>{
      this.idRef = idRef;
    })
  },

  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    updateAuteurs() {
      if (!this.filtre_auteurs) {
        this.nbResult = this.auteurs.length;
        return;
      }
      const filteredAuteurs = this.auteurs.filter(auteur =>
          (auteur.nom && auteur.nom.toLowerCase().includes(this.filtre_auteurs.toLowerCase())) ||
          (auteur.prenom && auteur.prenom.toLowerCase().includes(this.filtre_auteurs.toLowerCase())) ||
          (auteur.email && auteur.email.toLowerCase().includes(this.filtre_auteurs.toLowerCase())) ||
          (auteur.organisme && auteur.organisme.toLowerCase().includes(this.filtre_auteurs.toLowerCase()))
      );

      this.localAuteurs = filteredAuteurs;
      this.nbResult = filteredAuteurs.length;
    },
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    supprimerAuteur(auteur) {
      // Mettez à jour votre logique de suppression d'auteur ici
      const index = this.auteursRef.findIndex((a) => a.idAuteur === auteur.idAuteur);
      if (index !== -1) {
        this.auteursRef.splice(index, 1);
      }
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    onRowSelected(item) {
      eventBus.$emit('updateAuteurSelected',item.idAuteur);
      this.idAuteur = item.idAuteur;
      const doublon = this.auteursRef.some((auteur)=>{
        return auteur.idAuteur === item.idAuteur
      })
      if(!doublon){
        this.auteursRef.push(item);
      }
    }

  },

  watch: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    filtre_auteurs(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1;
        this.updateAuteurs();
      }
    }
  },

  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    rows() {
      return this.auteurs.length
    },
  },

}
</script>
<style scoped>

.auteursApp{
  text-align: center;
  justify-content: center;
}
</style>