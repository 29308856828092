<template>
  <div class="organismeList">
    <div class="tableSecondElement">
      <b-table sticky-header
               striped bordered small
               :items="organismesApp"
               :fields="fields"
               primary-key="idOrganisme">

        <template #cell(idOrganisme)="data">
          {{data.item.idOrganisme}}
        </template>
        <template #cell(actions)="data">
          <a @click="supprimerOrganisme(data.item)" class="icon_delete" title="supprimer l'organisme"><i class="fa-solid fa-trash-can"></i></a>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>

export default {
  name: "OrganismeAppList",

  props:{
    organismesApp:{
      type:Array,
      required:true
    },

    lectureSeule:{
      type:Boolean,
      default:true
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data(){
    return{
      organisme:{},
      idOrganisme:'',

      fields: [
        {key: 'idOrganisme', label:'Id', class: 'text-center'},
        {key: 'sigle', label:'Sigle', class: 'text-center'},
        {key: 'nom', label:'Nom', class: 'text-center'},
        {key: 'noDept', label:'Département', class: 'text-center'},
        {key: 'ville', label:'Ville', class: 'text-center'},
        { key: "actions", label: "Actions", class: "text-center" },],
      items: [
        { key: "idOrganisme", label: "Id", sortable: true, tdClass: "text-left", thClass: "text-left" },
        { key: "sigle", label: "Sigle", label_long: "Sigle:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "nom", label: "Nom", label_long: "Nom:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "noDept", label: "Département", label_long: "Département:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "ville", label: "Ville", label_long: "Ville:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
      ],
    }
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    supprimerOrganisme(organisme) {
      this.$emit("deleteOrganisme", organisme);
    },
  },
}
</script>
<style scoped>

</style>