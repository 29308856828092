<template>
  <div class="applicationList">
    <div class="tableElement">
      <b-table sticky-header
       striped bordered small selectable
       :items="applications"
       :fields="fields"
       primary-key="idApplication"
       :select-mode="selectMode"
       :per-page="perPage"
       :current-page="currentPage"
       :filter="filtre_applications"
       :filter-included-fields="['titre','annee']"
       ref="selectableTable"
       @row-clicked="onRowSelected"
       id="tableAppMethode">

        <template #cell(selection)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
          </template>
        </template>
        <template #cell(idApplication)="data">
          {{data.item.idApplication}}
        </template>
      </b-table>
    </div>
    <b-row>
      <b-col cols="1" class="icon">
        <a class="icon" v-if="idApplication !==null" title="voir l'application sélectionnée" v-b-modal ="'my-modal12'"
          @click.prevent ="showModalAfficher =!showModalAfficher" align-self="center"><i class="fa-solid fa-eye" id="showApp"></i></a>
        <a class="icon" v-else title="voir l'application sélectionnée" @click="selectApp" align-self="center"><i class="fa-solid fa-eye" id="showApp"></i></a>
      </b-col>
      <b-col cols="3" class="icon">
        <b-form-group label-for="filter-input" label-align-sm="right" label-size="sm">
          <b-input-group size="sm">
            <b-form-input id="filter-input" v-model="filtre_applications" type="search" placeholder="Rechercher Application"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filtre_applications" @click="filtre_applications = ''"><i class="fa-solid fa-arrows-rotate"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <div class="pagination">
          <p>{{nbResult}} application(s) sur {{rows}}</p>
        </div>
        <div class="pagination">
          <b-pagination class="customPagination"
            v-model="currentPage"
            :total-rows="nbResult !== 0 ? nbResult : rows"
            :per-page="perPage"
            aria-controls="my-card"
            size="sm"
            align="right"
            id="paginationAppMethode">
          </b-pagination>
        </div>
      </b-col>
    </b-row>
    <div id="my-modal12" class="mb-4">
      <form>
        <ModalAfficher :id="idApplication" :lectureSeule="lectureSeule" v-if="showModalAfficher" @close="showModalAfficher = false" class="center"></ModalAfficher>
      </form>
    </div>

    <b-row>
        <b-button id="methode" class="app" block v-b-modal.modal-lg ="'my-modal13'" @click.prevent ="showModalMethode = !showModalMethode; showModalEtapes=false ">
            Méthode générale de mise en oeuvre de l'application
        </b-button>
    </b-row>
    <div id="my-modal13" class="mt-4 mb-4" v-if="showModalMethode" @close="showModalMethode = false">
      <ModalMethode
          :idApplicationMeth="idApplication"
          :lectureSeule="lectureSeule"
          @close="showModalMethode = false">
      </ModalMethode>
    </div>
    <b-row>
      <b-button class="app" id="etape" block v-b-modal.modal-lg ="'my-modal14'" @click.prevent ="showModalEtapes = !showModalEtapes; showModalMethode=false ">
        Renseignement des étapes de traitement de la méthode
      </b-button>
    </b-row>
    <div id="my-modal14" v-if="showModalEtapes" @close="showModalEtapes = false">
      <div v-if="etapesMeth.length!==0" class="etapesApp mt-3">
        <b-card class="card-list">
          <p style="color: white"><strong><u>Ensemble des étapes sélectionnées pour la méthode générale de mise en oeuvre de l'application :</u></strong></p>
          <EtapesMeth
              :etapesMeth ="etapesMeth"
              :idApp = "idApplication">
          </EtapesMeth>
          <div class="bouton">
            <b-button pill v-b-modal.modal-lg ="'my-modal19'" @click.prevent ="showModalCaractEtape = !showModalCaractEtape" class="valid m-2">Ajouter une nouvelle étape</b-button>
          </div>
        </b-card>
      </div>
      <div v-else v-b-modal.modal-lg ="'my-modal19'" id="addEtapeFromApp" @click.prevent ="showModalCaractEtape = !showModalCaractEtape " class="bouton"><b-button pill class="valid m-2">Ajouter une étape</b-button></div>
      <div id="my-modal19" v-if="showModalCaractEtape" class="etape mt-3">
        <ModalEtape
          :idMethodeEtape="idMethode"
          :etapesMeth ="etapesMeth"
          :idApp = "idApplication">
        </ModalEtape>
      </div>
    </div>
  </div>
</template>

<script>



import ModalMethode from "@/components/Methode/Methode/MethodeGeneraleForm.vue";
import ModalEtape from "@/components/Methode/Etape/ModalAjouterEtape.vue";
import http from "@/http";
import eventBus from "@/eventBus";
import ModalAfficher from "@/components/Methode/Application/ModalAfficherAppMethode.vue";
import EtapesMeth from "@/components/Methode/Etape/ListEtapesByMethode.vue";

export default {
  name: "ApplicationsMethodeList",
  components: {EtapesMeth, ModalAfficher, ModalMethode, ModalEtape},

  props:{
    applications:{
      type:Array,
      required:true
    },
    lectureSeule:{
      type:Boolean,
      default:true
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data(){
    return{
      application:{},
      etapesMeth:[],
      perPage: 10,
      currentPage: 1,
      selectMode: 'single',
      showModalMethode:false,
      showModalEtapes:false,
      showModalCaractEtape: false,
      showModalAfficher:false,
      idApplication: null,
      idMethode:null,
      filtre_applications:"",
      nbResult:0,
      localApplications: [],

      fields: [
        {key: 'selection', label:'Sélection', class: 'text-center'},
        {key: 'idApplication', label:'Id', class: 'text-center'},
        {key: 'titre', label:'Titre', class: 'text-center'},
        {key: 'annee', label:'Année', class: 'text-center'},
        {key: 'idMethode', label:'Id Meth.', class: 'text-center'}],
      items: [
        { key: "idApplication", label: "Id", sortable: true, tdClass: "text-left", thClass: "text-left" },
        { key: "titre", label: "Titre", label_long: "Titre:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "annee", label: "Année", label_long: "Année:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "idMethode", label: "Id Meth.", label_long: "Id Meth.:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
      ],
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted(){
    eventBus.$on('reloadEtapesMeth', (idApplication)=>{
      this.getEtapesMeth(idApplication);
    })

    eventBus.$on('updateApplicationMethSelected',(idApplication)=>{
      this.getEtapesMeth(idApplication);
    })
  },

  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    updateApplications() {
      if (!this.filtre_applications) {
        this.nbResult = this.applications.length;
        return;
      }
      const filteredApplications = this.applications.filter(application =>
          (application.titre && application.titre.toLowerCase().includes(this.filtre_applications.toLowerCase())) ||
          (application.annee && application.annee.toString().includes(this.filtre_applications))
      );
      this.localApplications = filteredApplications;
      this.nbResult = filteredApplications.length;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    onRowSelected(item) {
      eventBus.$emit('updateApplicationMethSelected',item.idApplication);
      this.idApplication = item.idApplication;
      this.application = http.loadApplication(item.idApplication).then((data)=>{
        this.application = data;
        this.idMethode = data.idMethode;
      })
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    selectApp(){
      this.$bvToast.toast( `Veuillez sélectionner une application`, {
        variant: "danger",
        autoHideDelay: 5000,
        toaster: "b-toaster-top-center",
        noCloseButton: true,
        solid: true
      });
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getEtapesMeth(idApplication){
      http.getEtapesByMethodeInApp(idApplication).then((data)=>{
        this.etapesMeth = data;
      })
    },
  },

  watch: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    filtre_applications(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1;
        this.updateApplications();
      }
    },
  },

  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    rows() {
      return this.applications.length
    }
  },

}
</script>
<style scoped>

.app{
  background-color: #66c1bf !important;
  justify-content: center !important;
  color: black !important;
}

.etapesApp{
  text-align: center;
  justify-content: center;
}

</style>