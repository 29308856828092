<template>
  <div class="thematiqueList">
    <div class="tableElement">
      <b-table sticky-header
               striped bordered small selectable
               :items="thematiques"
               :fields="fields"
               primary-key="idThematique"
               :select-mode="selectMode"
               :per-page="perPage"
               :current-page="currentPage"
               :filter="filtre_thematiques"
               :filter-included-fields="['groupe','thematiquePrincipale','sousThematique']"
               ref="selectableTable"
               @row-clicked="onRowSelected"
               id="tableThematique">

        <template #cell(selection)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
          </template>
        </template>
        <template #cell(idThematique)="data">
          {{data.item.idThematique}}
        </template>
      </b-table>
    </div>
    <b-row>
      <b-col cols="3" class="icon">
        <b-form-group label-for="filter-input" label-align-sm="right" label-size="sm">
          <b-input-group size="sm">
            <b-form-input id="filter-input-thematique" v-model="filtre_thematiques" type="search" placeholder="Rechercher Thematique"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filtre_thematiques" @click="filtre_thematiques = ''"><i class="fa-solid fa-arrows-rotate"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <div class="pagination">
          <p>{{nbResult}} thématique(s) sur {{rows}}</p>
        </div>
        <div class="pagination">
          <b-pagination class="customPagination"
                        v-model="currentPage"
                        :total-rows="nbResult !== 0 ? nbResult : rows"
                        :per-page="perPage"
                        aria-controls="my-card"
                        size="sm"
                        align="right"
                        id="paginationThem">
          </b-pagination>
        </div>
      </b-col>
    </b-row>

    <div v-if="thematiquesApp.length!==0" class="thematiquesApp mt-3">
      <b-card class="card-list">
        <p style="color: white"><strong><u>Liste des Thématiques de l'application séléctionnée:</u></strong></p>
        <ThematiqueApp
            :thematiquesApp="thematiquesApp"
            @deleteThematique="handleDeleteThematique">
        </ThematiqueApp>
      </b-card>
      <div class="bouton">
        <b-button size="sm" type="submit" v-on:click="insertThematiquesFromApp(thematiquesApp)" class="btn-ajout" id="addThemToApp">Enregistrer la séléction</b-button>
        <b-button size="sm" class="btn-ajout" @click="clearTable">Réinitialiser</b-button>
      </div>

    </div>

  </div>
</template>
<script>

import http from "@/http";
import eventBus from "@/eventBus";
import ThematiqueApp from "@/components/Applications/Thematique/ThematiquesAppList.vue"

export default {
  name: "Thematique",
  components: {ThematiqueApp},

  props:{
    thematiques:{
      type:Array,
      required:true
    },
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data(){
    return{
      thematique:{},
      thematiquesApp: [],
      perPage: 10,
      currentPage: 1,
      pageOptions:[10,20,30],
      selectMode: 'multi',
      idThematique: '',
      filtre_thematiques:"",
      localThematiques: [],
      nbResult:0,

      fields: [
        {key: 'selection', label:'Sélection', class: 'text-center'},
        {key: 'idThematique', label:'Id', class: 'text-center'},
        {key: 'groupe', label:'Groupe thématique', class: 'text-center'},
        {key: 'thematiquePrincipale', label:'Thématique', class: 'text-center'},
        {key: 'sousThematique', label:'Sous thématique', class: 'text-center'}],
      items: [
        { key: "idThematique", label: "Id", sortable: true, tdClass: "text-left", thClass: "text-left" },
        { key: "groupe", label: "Groupe thématique", label_long: "Groupe thématique:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "thematiquePrincipale", label: "Thématique", label_long: "Thématique:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "sousThematique", label: "Sous thématique", label_long: "Sous thématique:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },

      ],
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted(){
    eventBus.$on('updateApplicationSelected',(idApplication)=>{
      this.getThematiquesApp(idApplication);
      this.idApplication = idApplication;
    })
  },

  methods: {
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    updateThematiques() {
      if (!this.filtre_thematiques) {
        this.nbResult = this.thematiques.length;
        return;
      }
      const filteredThematiques = this.thematiques.filter(thematique =>
          (thematique.groupe && thematique.groupe.toLowerCase().includes(this.filtre_thematiques.toLowerCase())) ||
          (thematique.thematiquePrincipale && thematique.thematiquePrincipale.toLowerCase().includes(this.filtre_thematiques.toLowerCase())) ||
          (thematique.sousThematique && thematique.sousThematique.toLowerCase().includes(this.filtre_thematiques.toLowerCase()))
      );

      this.localThematiques = filteredThematiques;
      this.nbResult = filteredThematiques.length;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    handleDeleteThematique(thematique) {
      this.thematiquesApp = this.thematiquesApp.filter(t => t.idThematique !== thematique.idThematique);
      this.$nextTick(() => {
        this.clearSelected();
      });
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getThematiquesApp(idApplication){
     http.getThematiquesFromApp(idApplication).then((data)=>{
       this.thematiquesApp = data;
     });
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    insertThematiquesFromApp(thematiquesApp){
      if(this.idApplication !== undefined){
        http.deleteThematiqueFromApp(this.idApplication).then(()=>{
          //
        }).then(()=>{
          return Promise.all(thematiquesApp.map(them => http.insertThematiquesFromApp(this.idApplication, them.idThematique)));
        }).then(()=>{
          this.clearSelected();
          this.$bvToast.toast(`Thématique(s) ajoutée(s) à l'application avec succès`, {
            variant: "success",
            autoHideDelay: 5000,
            toaster: "b-toaster-top-center",
            noCloseButton: true,
            solid: true
          });
        })
      }else{
        this.$bvToast.toast( ` Veuillez selectionner une application`, {
          variant: "danger",
          autoHideDelay: 5000,
          toaster: "b-toaster-top-center",
          noCloseButton: true,
          solid: true
        });
      }
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    clearTable(){
      this.thematiquesApp=[];
      this.clearSelected();
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    onRowSelected(item) {
      this.idThematique = item.idThematique;
      const doublon = this.thematiquesApp.some((thematique)=>{
        return thematique.idThematique === item.idThematique
      })
      if(!doublon){
        this.thematiquesApp.push(item);
      }
    }
  },
  watch: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    filtre_thematiques(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1;
        this.updateThematiques();
      }
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    rows() {
      return this.thematiques.length
    }
  },

}
</script>
<style scoped>

.thematiquesApp{
  text-align: center;
  justify-content: center;
}

</style>