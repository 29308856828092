<template>
  <div class="applications">
    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="card p-1" role="tab">
          <b-button block v-b-toggle.accordion-1 class="app">Applications thématiques</b-button>
        </b-card-header>
        <b-card-body id="accordion-1">
          <ApplicationList
            :applications="applications">
          </ApplicationList>
        </b-card-body>
      </b-card>

      <b-row>
        <b-col>
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="card p-1" role="tab">
              <b-button block v-b-toggle.accordion-2 id="localisation" class="app">Localisation de l'application </b-button>
            </b-card-header>
          </b-card>
        </b-col>

        <b-col>
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="card p-1" role="tab">
              <b-button block v-b-toggle.accordion-3 id="source" class="app">Personne(s) source </b-button>
            </b-card-header>
          </b-card>
        </b-col>

        <b-col>
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="card p-1" role="tab">
              <b-button block v-b-toggle.accordion-4 id="thematique" class="app">Thématique(s)</b-button>
            </b-card-header>
          </b-card>
        </b-col>

        <b-col>
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="card p-1" role="tab">
              <b-button block v-b-toggle.accordion-5 id="orgD" class="app">Organisme demandeur</b-button>
            </b-card-header>
          </b-card>
        </b-col>

        <b-col>
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="card p-1" role="tab">
              <b-button block v-b-toggle.accordion-6 id="orgC" class="app">Organisme concepteur</b-button>
            </b-card-header>
          </b-card>
        </b-col>

        <b-col>
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="card p-1" role="tab">
              <b-button block v-b-toggle.accordion-7 id="reference" class="app">Références bibliographiques</b-button>
            </b-card-header>
          </b-card>
        </b-col>
      </b-row>

      <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <p class="titreOnglet">Localisation de l'application </p>
          <Localisation>
          </Localisation>
        </b-card-body>
      </b-collapse>

      <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <p class="titreOnglet">Personne(s) source d'information sur l'application  </p>
          <Auteur
              :auteurs="auteurs">
          </Auteur>
        </b-card-body>
      </b-collapse>

      <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <p class="titreOnglet">Thématique(s) de l'application  </p>
          <Thematique
              :thematiques="thematiques">
          </Thematique>
        </b-card-body>
      </b-collapse>

      <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <p class="titreOnglet">Organisme demandeur de l'application </p>
          <ListOrgDemandeur
              :organismes="organismes">
          </ListOrgDemandeur>
        </b-card-body>
      </b-collapse>

      <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <p class="titreOnglet">Organisme concepteur de l'application  </p>
          <ListOrgConcepteur
              :organismes="organismes">
          </ListOrgConcepteur>
        </b-card-body>
      </b-collapse>

      <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <p class="titreOnglet">Références bibliographiques  </p>
          <RefBiblio
              :refsBiblios="refsBiblios">
          </RefBiblio>
        </b-card-body>
      </b-collapse>

    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus.js'
import http from '@/http.js'
import ApplicationList from "@/components/Applications/Application/ApplicationList.vue";
import Localisation from "@/components/Applications/Localisation/LocalisationList.vue";
import Auteur from "@/components/Applications/Auteur/AuteurList.vue";
import ListOrgDemandeur from "@/components/Applications/Organisme/ListOrganismeDemandeur.vue"
import ListOrgConcepteur from "@/components/Applications/Organisme/ListOrganismeConcepteur.vue";
import RefBiblio from "@/components/Applications/RefBiblio/RefBiblioList.vue";
import Thematique from "@/components/Applications/Thematique/ThematiqueList.vue";
export default {
  name: "ApplicationsList",
  components:{
    ApplicationList,
    Localisation,
    Auteur,
    ListOrgConcepteur,
    ListOrgDemandeur,
    RefBiblio,
    Thematique,
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      applications:[],
      applicationData: {},
      auteurs:[],
      auteurData: {},
      organismes:[],
      organismeData: {},
      refsBiblios: [],
      refBiblioData: {},
      thematiques: [],
      thematiqueData: {},
    };
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted(){
    eventBus.$on("reloadApplications",() => {
      this.getApplications();
    })
    eventBus.$on("reloadAuteurs",() => {
      this.getAuteurs();
    })
    eventBus.$on("reloadOrganismes",() => {
      this.getOrganismes();
    })
    eventBus.$on("reloadRefs",() => {
      this.getRefs();
    })
    this.getThematiques();
  },

  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getApplications(){
      http.getApplications().then((data) => {
      this.applications = data;
      });
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getAuteurs(){
      http.getAuteurs().then((data) => {
        this.auteurs = data;
      });
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getOrganismes(){
      http.getOrganismes().then((data) => {
        this.organismes = data;
      })
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getRefs(){
      http.getRefs().then((data)=>{
        this.refsBiblios = data;
      })
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getThematiques(){
      http.getThematiques().then((data)=>{
        this.thematiques = data;
      })
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  created(){
    this.getApplications();
    this.getAuteurs();
    this.getOrganismes();
    this.getRefs();
    this.getThematiques();
  },

}
</script>
<style>
#accordion-1 {
  background-color: aliceblue;
}
#accordion-2 {
  background-color: aliceblue;
}
#accordion-3 {
  background-color: aliceblue;
}
#accordion-4 {
  background-color: aliceblue;
}
#accordion-5 {
  background-color: aliceblue;
}
#accordion-6 {
  background-color: aliceblue;
}
#accordion-7 {
  background-color: aliceblue;
}
.applications{
  margin-left: 4em;
  margin-right: 4em;
  background-color: aliceblue;
}
.app{
  background-color: #66c1bf !important;
}
.titreOnglet{
  display: flex;
  font-size: 20px;
  justify-content: center;
  text-decoration: underline;
  font-weight: bold;
}
</style>