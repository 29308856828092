<template>
  <div>
    <b-card v-if="show" class="modal-container">
      <ValidationObserver ref="observeraddetape">
      <form @submit.prevent="emitInsertEtape" id="addEtape">
        <div>
          <p><strong>I. Caractéristiques de l'étape de traitement</strong></p>
          <b-card class="formModal mr-4 ml-4">
            <b-row>
              <b-col cols="3">
                <b-form-group class="labelTitre" id="input-group-1" style="text-align: start" label-size="sm" label="ID Etape:" label-for="input-1">
                  <b-form-input id="input-1" style="width: 60%" size="sm" type="text">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <ValidationProvider name="Numéro" rules="required" v-slot="{errors}">
            <b-form-group class="labelTitre" id="input-group-2" label-size="sm" label="Numéro*:" label-for="input-2">
              <b-form-input id="input-2" size="sm" v-model="noEtape" type="text">
              </b-form-input>
              <span style="color: red">{{errors[0]}}</span>
            </b-form-group>
            </ValidationProvider>

            <ValidationProvider name="Nom" rules="required" v-slot="{errors}">
            <b-form-group class="labelTitre" id="input-group-2" label-size="sm" label="Nom*:" label-for="input-2">
              <b-form-input id="input-3" size="sm" v-model="nom" type="text">
              </b-form-input>
              <span style="color: red">{{errors[0]}}</span>
            </b-form-group>
            </ValidationProvider>

                <b-form-group class="labelTitre" id="input-group-3" label-size="sm" label="Type methode traitement:" label-for="input-3">
                  <b-form-select id="input-4" size="sm" v-model="typeMethTrait.code">
                    <option :value="null"></option>
                    <option v-for="type in typeMethTraits" v-bind:value="type.code" v-bind:key="type.code">{{type.signification}}</option>
                  </b-form-select>
                </b-form-group>

            <b-form-group class="labelTitre" id="input-group-2" label-size="sm" label="Résumé:" label-for="textarea">
              <b-form-textarea
                  id="textarea"
                  v-model="descriptionMethTrait"
                  rows="3"
                  max-rows="6"
              ></b-form-textarea>
            </b-form-group>

          </b-card>
        </div>
        <div class="bouton">
          <b-button pill type="submit" class="save m-2">Enregistrer les caractéristiques de l'etape</b-button>
        </div>

      </form>
      </ValidationObserver>
    </b-card>
    <div v-if="showModalDonnees" id="donneesEtapes" class="donnee mt-3">
      <ModalDonnee
          :donnees="donnees"
          :idEtape="idEtape">
      </ModalDonnee>
    </div>
    <div v-if="idEtape !== 0 && showAddMethEtape" class="bouton">
      <b-button pill type="submit" @click="insertEtapeFromMethode(etapeToAdd)" class="valid m-2" id="saveEtape">Enregistrer l'etape</b-button>
    </div>
  </div>
</template>

<script>

import http from "@/http";
import eventBus from '@/eventBus.js'
import ModalDonnee from "@/components/Methode/Etape/Donnees/TypesDonneesList.vue";
export default {
  name: 'InsertEtapeMethode',
  components: {ModalDonnee},

  props:{
    idMethodeEtape:{
      type:Number
    },
    etapesMeth:{
      type:Array,
      required:true
    },
    idApp:{
      type:Number,
      required:true
    },
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  destroyed(){
    eventBus.$off('insertEtape');
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data(){
    return {
      locale:"fr-FR",
      idEtape:0,
      idMeth:0,
      noEtape:'',
      nom : '',
      typeMethTraits: [],
      typeMethTrait:{},
      descriptionMethTrait:'',
      etapeTraitementData: Object,
      donnees:[],
      donneeData:{},
      show:true,
      showModalDonnees:false,
      etapeToAdd:{},
      showAddMethEtape:true,
    };
  },


  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  created(){
    http.getTypeMethTrait().then((data)=>{
      this.typeMethTraits = data;
    });
    this.getDonnees();
  },

  methods:{

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getDonnees(){
      http.getDonnees().then((data) => {
        this.donnees = data;
      });
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    insertEtapeFromMethode(){
      if(this.idEtape !== undefined){
        this.etapeToAdd = {
          idMeth : this.idMethodeEtape,
          idEtape : this.idEtape,
        }
        http.insertEtapeFromMethode(this.etapeToAdd).then(()=>{
        //
        }).then(()=>{
          this.show = false;
          this.showModalDonnees = false;
          this.showAddMethEtape = false;
          eventBus.$emit("reloadEtapesMeth",this.idApp);
          this.$bvToast.toast( `Etape ajoutée(s) à la méthode avec succès`,{
            variant: "success",
            autoHideDelay: 5000,
            toaster: "b-toaster-top-center",
            noCloseButton: true,
            solid: true
          });
        })
      }else{
        this.$bvToast.toast( ` Veuillez saisir une étape`, {
          variant: "danger",
          autoHideDelay: 5000,
          toaster: "b-toaster-top-center",
          noCloseButton: true,
          solid: true
        });
      }
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async emitInsertEtape(){
      if(this.idMethodeEtape !== null) {
        const isValid = await this.$refs.observeraddetape.validate();
        if(isValid){
          eventBus.$emit("insertEtape");
          this.showModalDonnees = true;
          this.show = false;
        } else {
          this.invalid = true;
        }
      }else{
        this.$bvToast.toast( ` Veuillez saisir une méthode`, {
          variant: "danger",
          autoHideDelay: 5000,
          toaster: "b-toaster-top-center",
          noCloseButton: true,
          solid: true
        });
      }
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted(){

    eventBus.$on("reloadDonnees",() => {
      this.getDonnees();
    })
    this.getDonnees();

    eventBus.$on('insertEtape', () =>{
      this.etapeTraitementData = {
        idEtape : this.idEtape,
        noEtape : this.noEtape,
        nom : this.nom,
        typeMethTrait: this.typeMethTrait,
        descriptionMethTrait : this.descriptionMethTrait,
      };
      http.insertEtape(this.etapeTraitementData).then((data) => {
        this.idEtape = data
      }).then(() => {
        this.$emit('close');
      }).finally(()=>{
        this.$bvToast.toast( `L'étape a été ajoutée avec succès`,{
          variant: "success",
          autoHideDelay: 5000,
          toaster: "b-toaster-top-center",
          noCloseButton: true,
          solid: true
        });
      })
    });
  }
}
</script>

<style scoped>

.modal-container {
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;

}

.labelTitre{
  font-size: 14px;
}

.save{
  color: white !important;
  font-size: 15px;
  text-align: right;
  margin:auto;
  background-color: #00a3a6 !important;
}
.save:hover{
  color: black !important;
}

</style>