<template>
  <div class="principale">
    <b-card v-if="show" class="modal-container">
      <ValidationObserver ref="observermodifieretape">
        <form @submit.prevent="emitModifierEtape">
          <div>
            <p><strong>I. Caractéristiques de l'étape de traitement</strong></p>
            <b-card class="formModal mr-4 ml-4">
              <b-row>
                <b-col cols="3">
                  <b-form-group class="labelTitre" id="input-group-1" style="text-align: start" label-size="sm" label="ID Etape:" label-for="input-1">
                    <b-form-input id="input-1" style="width: 60%" size="sm" v-model="idEtape" type="text">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <ValidationProvider name="Numéro" rules="required" v-slot="{errors}">
                <b-form-group class="labelTitre" id="input-group-2" label-size="sm" label="Numéro*:" label-for="input-2">
                  <b-form-input id="input-2" size="sm" v-model="noEtape" type="text">
                  </b-form-input>
                  <span style="color: red">{{errors[0]}}</span>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider name="Nom" rules="required" v-slot="{errors}">
                <b-form-group class="labelTitre" id="input-group-2" label-size="sm" label="Nom*:" label-for="input-2">
                  <b-form-input id="input-3" size="sm" v-model="nom" type="text">
                  </b-form-input>
                  <span style="color: red">{{errors[0]}}</span>
                </b-form-group>
              </ValidationProvider>

              <b-form-group class="labelTitre" id="input-group-3" label-size="sm" label="Type methode traitement:" label-for="input-3">
                <b-form-select id="input-4" size="sm" v-model="typeMethTrait.code">
                  <option :value="null"></option>
                  <option v-for="type in typeMethTraits" v-bind:value="type.code" v-bind:key="type.code">{{type.signification}}</option>
                </b-form-select>
              </b-form-group>

              <b-form-group class="labelTitre" id="input-group-2" label-size="sm" label="Résumé:" label-for="textarea">
                <b-form-textarea
                    id="textarea"
                    v-model="descriptionMethTrait"
                    rows="3"
                    max-rows="6"
                ></b-form-textarea>
              </b-form-group>

            </b-card>
          </div>
          <div class="bouton">
            <b-button pill type="submit" class="save m-2" id="modifEtape">Enregistrer les caractéristiques de l'etape</b-button>
            <b-button pill @click="showModal" class="save m-2">Accéder aux données</b-button>
          </div>

        </form>
      </ValidationObserver>
    </b-card>
    <div v-if="showModalDonnees" class="donnee mt-3">
      <ModalDonnee
          :donnees="donnees"
          :idEtape="idEtape">
      </ModalDonnee>
    </div>
  </div>
</template>

<script>

import http from "@/http";
import eventBus from '@/eventBus.js'
import ModalDonnee from "@/components/Methode/Etape/Donnees/TypesDonneesList.vue";
export default {
  name: 'UpdateEtapeMethode',
  components: {ModalDonnee},

  props:{
    id:{
      type:Number
    },
    idApp:{
      type:Number,
      required:true
    },
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  destroyed(){
    eventBus.$off('modifierEtape');
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data(){
    return {
      locale:"fr-FR",
      idEtape:'',
      identifiantEtape: this.id,
      noEtape:'',
      nom : '',
      typeMethTraits: [],
      typeMethTrait:{},
      descriptionMethTrait:'',
      etapeTraitementData: Object,
      donnees:[],
      donneeData:{},
      show:true,
      showModalDonnees:false,
    };
  },


  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  created(){
    http.getTypeMethTrait().then((data)=>{
      this.typeMethTraits = data;
    });

    if(this.id !== undefined){
      http.loadEtape(this.id).then((data)=>{
        this.idEtape = data.idEtape;
        this.noEtape = data.noEtape;
        this.nom = data.nom;
        if (data.typeMethTrait !== null && data.typeMethTrait !== undefined) {
          this.typeMethTrait = data.typeMethTrait;
        }
        this.descriptionMethTrait = data.descriptionMethTrait;
      })
    }
  },

  methods:{

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async emitModifierEtape(){
      eventBus.$emit("modifierEtape");
      eventBus.$emit("reloadEtapesMeth", this.idApp);
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getDonnees(){
      http.getDonnees().then((data) => {
        this.donnees = data;
      });
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    showModal(){
      this.showModalDonnees =! this.showModalDonnees;
      this.show =! this.show;
    },
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted(){

    eventBus.$on("reloadDonnees",() => {
      this.getDonnees();
    })
    this.getDonnees();

    eventBus.$on('modifierEtape', () =>{
      this.etapeTraitementData = {
        idEtape : this.idEtape,
        noEtape : this.noEtape,
        nom : this.nom,
        typeMethTrait: this.typeMethTrait,
        descriptionMethTrait : this.descriptionMethTrait,
      };
      http.updateEtape(this.etapeTraitementData).then((data) => {
        this.identifiantEtape = data
      }).then(() => {
        this.showModal();
      }).finally(()=>{
        this.$bvToast.toast( `L'étape' ${this.etapeTraitementData.idEtape} 'a été modifiée avec succès`,{
          variant: "success",
          autoHideDelay: 5000,
          toaster: "b-toaster-top-center",
          noCloseButton: true,
          solid: true
        });
      })
    });
  }
}
</script>

<style scoped>

.modal-container {
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;

}

.labelTitre{
  font-size: 14px;
}

.save{
  color: white !important;
  font-size: 15px;
  text-align: right;
  margin:auto;
  background-color: #00a3a6 !important;
}
.save:hover{
  color: black !important;
}

.principale{
  background-color: aliceblue;
  border-radius: 20px;
  margin-top: 1em;
  padding-bottom: 10px;
}

</style>