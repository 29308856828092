<template>
  <transition name="modal">
    <b-card class="modal-container">
      <ValidationObserver ref="observermodiforganisme">
      <form @submit.prevent="emitModifierOrganisme" id="modifOrg">
        <div class="titreModal pt-2 pb-2">
          <h5>Modifier l'organisme sélectionnée</h5>
        </div>

        <div>
          <b-card class=" formModal mr-4 ml-4">
            <ValidationProvider name="Type" rules="required" v-slot="{errors}">
            <b-form-group class="labelTitre" id="input-group-1" label-size="sm" label="Type*:" label-for="input-horizontal">
              <b-form-select id="input-1" v-model="noTypeOrg.code" >
                <option v-for="n in noTypeOrgs" v-bind:value="n.code" v-bind:key="n.code">{{n.signification}}</option>
              </b-form-select>
              <span style="color: red">{{errors[0]}}</span>
            </b-form-group>
            </ValidationProvider>
            <b-row>
              <b-col cols="6">
                <ValidationProvider name="Sigle" rules="required" v-slot="{errors}">
                  <b-form-group class="labelTitre" id="input-group-2" label-size="sm" label="Sigle*:" label-for="input-2">
                    <b-form-input id="input-2" v-model="sigle" type="text" size="sm">
                    </b-form-input>
                    <span style="color: red">{{errors[0]}}</span>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-form-group class="labelTitre" id="input-group-2" label-size="sm" label="Nom:" label-for="input-2">
              <b-form-input id="input-3" v-model="nom" type="text" size="sm">
              </b-form-input>
            </b-form-group>
            <b-form-group class="labelTitre" id="input-group-3" label-size="sm" label="Service:" label-for="input-3">
              <b-form-input id="input-4" v-model="service" type="text" size="sm">
              </b-form-input>
            </b-form-group>
            <ValidationProvider name="Département" rules="required" v-slot="{errors}">
              <b-form-group class="labelTitre" id="input-group-4" label-size="sm" label="Département*:" label-for="input-4">
                <b-form-select id="input-5" v-model="localisation.noDept" >
                  <option v-for="l in localisations" v-bind:value="l.noDept" v-bind:key="l.noDept">{{l.nomDept}}</option>
                </b-form-select>
                <span style="color: red">{{errors[0]}}</span>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="Ville" rules="required" v-slot="{errors}">
              <b-form-group class="labelTitre" id="input-group-5" label-size="sm" label="Ville*:" label-for="input-6">
                <b-form-input id="input-6" v-model="ville" type="text" size="sm">
                </b-form-input>
                <span style="color: red">{{errors[0]}}</span>
              </b-form-group>
            </ValidationProvider>
            <b-form-group class="labelTitre" id="input-group-6" label-size="sm" label="Adresse:" label-for="input-7">
              <b-form-input id="input-7" v-model="adresse" type="text" size="sm">
              </b-form-input>
            </b-form-group>
            <b-row>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-7" label-size="sm" label="N° tél:" label-for="input-8">
                  <b-form-input id="input-8" v-model="tel" type="text" size="sm"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-8" label-size="sm" label="N° fax:" label-for="input-8">
                  <b-form-input id="input-9" v-model="fax" type="text" size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group class="labelTitre" id="input-group-9" label-size="sm" label="Adresse Web:" label-for="input-8">
              <b-form-input id="input-10" v-model="web" type="text" size="sm">
              </b-form-input>
            </b-form-group>
            <ValidationProvider name="Email" rules="email" v-slot="{errors}">
            <b-form-group class="labelTitre" id="input-group-10" label-size="sm" label="Email:" label-for="input-8">
              <b-form-input id="input-11" v-model="email" type="text" size="sm">
              </b-form-input>
              <span style="color:red;">{{errors[0]}}</span>
            </b-form-group>
            </ValidationProvider>
          </b-card>
        </div>
        <div class="bouton">
          <b-button pill type="submit" class="valid m-2">Valider</b-button>
          <b-button pill class="closed m-2" @click="$emit('close')" id="close">Annuler</b-button>
        </div>
      </form>
      </ValidationObserver>
    </b-card>
  </transition>
</template>

<script>
import http from "@/http";
import eventBus from '@/eventBus.js'
import {extend} from 'vee-validate'
import {email, required} from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Ce champ est obligatoire'
});
extend('email', {
  ...email,
  message: "Le format de l'email saisi est incorrect"
});

  export default {
    name: 'ModifOrganisme',

    props:{
      id:{
        type: Number
      }
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    destroyed(){
      eventBus.$off('modifierOrganisme');
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data(){
      return {
        locale:"fr-FR",
        identifiantOrganisme: this.id,
        idOrganisme:'',
        noTypeOrgs: [],
        noTypeOrg: { code: '' },
        sigle:'',
        nom: '',
        service:'',
        adresse:'',
        localisations: [],
        localisation:{},
        ville:'',
        tel: '',
        fax:'',
        email:'',
        web:'',
        invalid:false,
        organismeData: Object,
      };
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    created(){
      http.getLocalisations().then((data)=>{
        this.localisations = data;
      });
      http.getTypeOrgs().then((data)=>{
        this.noTypeOrgs = data;
      });

      if(this.id !== undefined){
        http.loadOrganisme(this.id).then((data)=>{
          this.idOrganisme = data.idOrganisme;
          if(data.noTypeOrg !== null && data.noTypeOrg !== undefined){
            this.noTypeOrg = data.noTypeOrg;
          }
          this.sigle = data.sigle;
          this.nom = data.nom;
          this.adresse = data.adresse;
          this.service = data.service;
          this.localisation = data.localisation;
          this.ville = data.ville;
          this.tel = data.tel;
          this.fax = data.fax;
          this.email = data.email;
          this.web = data.web;
        })
      }
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    methods:{
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      async emitModifierOrganisme(){
        const isValid = await this.$refs.observermodiforganisme.validate();
        if (isValid){
          eventBus.$emit("modifierOrganisme");
        } else{
          this.invalid = true;
        }

      }
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    mounted(){

      eventBus.$on('modifierOrganisme', () =>{
        this.organismeData = {
          idOrganisme : this.idOrganisme,
          noTypeOrg : this.noTypeOrg,
          sigle : this.sigle,
          nom : this.nom,
          service : this.service,
          adresse : this.adresse,
          localisation : this.localisation,
          ville : this.ville,
          tel : this.tel,
          fax : this.fax,
          email : this.email,
          web : this.web,
        };
        http.updateOrganisme(this.organismeData).then((data) => {
          this.identifiantOrganisme = data
        }).then(() => {
          this.$emit('close');
          eventBus.$emit("reloadOrganismes");
        }).catch(() => {
          this.$bvToast.toast("Erreur lors de la mise à jour de l'organisme", {
            variant: "danger",
            autoHideDelay: 5000,
            toaster: "b-toaster-top-center",
            noCloseButton: true,
            solid: true
          });
        }).finally(()=>{
          this.$bvToast.toast( `L'organisme' ${this.organismeData.idOrganisme} a été modifié avec succès`,{
            variant: "success",
            autoHideDelay: 5000,
            toaster: "b-toaster-top-center",
            noCloseButton: true,
            solid: true
          });
        })
      });
    }

  }


</script>

<style>

</style>