<template>
  <div class="thematiqueList">
    <div class="tableSecondElement">
      <b-table sticky-header
               striped bordered small
               :items="thematiquesApp"
               :fields="fields"
               primary-key="idThematique">

        <template #cell(idThematique)="data">
          {{data.item.idThematique}}
        </template>
        <template #cell(actions)="data">
          <a @click="supprimerThematique(data.item)" class="icon_delete" title="supprimer la thematique'"><i class="fa-solid fa-trash-can"></i></a>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>

export default {
  name: "ThematiqueAppList",

  props:{
    thematiquesApp:{
      type:Array,
      required:true
    },

    lectureSeule:{
      type:Boolean,
      default:true
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data(){
    return{
      thematique:{},
      idThematique:'',

      fields: [
        {key: 'idThematique', label:'Id', class: 'text-center'},
        {key: 'groupe', label:'Groupe thématique', class: 'text-center'},
        {key: 'thematiquePrincipale', label:'Thématique', class: 'text-center'},
        {key: 'sousThematique', label:'Sous thématique', class: 'text-center'},
        { key: "actions", label: "Actions", class: "text-center" },],
      items: [
        { key: "idThematique", label: "Id", sortable: true, tdClass: "text-left", thClass: "text-left" },
        { key: "groupe", label: "Groupe thématique", label_long: "Groupe thématique:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "thematiquePrincipale", label: "Thématique", label_long: "Thématique:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "sousThematique", label: "Sous thématique", label_long: "Sous thématique:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },

      ],
    }
  },

  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    supprimerThematique(thematique) {
      this.$emit("deleteThematique", thematique);
    },
  },
}
</script>
<style scoped>

</style>