<template>
  <div class="refList">
    <div class="tableSecondElement">
      <b-table sticky-header
               striped bordered small
               :items="refsApp"
               :fields="fields"
               primary-key="idRef">

        <template #cell(idRef)="data">
          {{data.item.idRef}}
        </template>
        <template #cell(actions)="data">
          <a @click="supprimerRef(data.item)" class="icon_delete" title="supprimer la référence"><i class="fa-solid fa-trash-can"></i></a>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>

export default {
  name: "RefBibliosList",

  props:{
    refsApp:{
      type:Array,
      required:true
    },

    lectureSeule:{
      type:Boolean,
      default:true
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data(){
    return{
      refBiblio:{},
      idRef:'',

      fields: [
        {key: 'idRef', label:'Id', class: 'text-center'},
        {key: 'titre', label:'Titre', class: 'text-center'},
        {key: 'type', label:'Type réf', class: 'text-center'},
        {key: 'document', label:'Doc. source', class: 'text-center'},
        {key: 'annee', label:'Année publi.', class: 'text-center'},
        { key: "actions", label: "Actions", class: "text-center" },],
      items: [
        { key: "idRef", label: "Id", sortable: true, tdClass: "text-left", thClass: "text-left" },
        { key: "titre", label: "Titre", label_long: "Titre:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "type", label: "Type réf", label_long: "Type réf:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "document", label: "Doc. source", label_long: "Doc. source:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "annee", label: "Année publi.", label_long: "Année publi.:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },

      ],
    }
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    supprimerRef(reference) {
      this.$emit("deleteRef", reference);
    },
  },
}
</script>
<style scoped>

</style>