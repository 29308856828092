<template>
  <div class="organismeList">
    <div class="tableElement">
      <b-table sticky-header
               striped bordered small selectable
               :items="organismes"
               :fields="fields"
               primary-key="idOrganisme"
               :select-mode="selectMode"
               :per-page="perPage"
               :current-page="currentPage"
               :filter="filtre_organismes"
               :filter-included-fields="['sigle','nom','noDept','ville']"
               ref="selectableTable"
               @row-clicked="onRowSelected"
               id="tableOrgC">

        <template #cell(selection)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
          </template>
        </template>
        <template #cell(idOrganisme)="data">
          {{data.item.idOrganisme}}
        </template>
      </b-table>
    </div>
    <b-row>
      <b-col cols="1" class="icon"><a class="icon" title="ajouter un nouvel organisme" v-b-modal.modal-lg ="'my-modal6'"
                                      @click.prevent ="showModalAdd = !showModalAdd; showModalModif=false" align-self="center"><i class="fas fa-plus-circle" id="ajoutOrgC"></i></a>
      </b-col>
      <b-col cols="1" class="icon"><a class="icon" v-if="idOrganisme !== null" title="modifier l'organisme sélectionnée" v-b-modal.modal-lg ="'my-modal7'"
                                      @click.prevent ="showModalModif = !showModalModif;showModalAdd=false" align-self="center"><i class="fa-solid fa-pen-to-square" id="modifOrgC"></i></a>
        <a class="icon" v-else title="modifier l'organisme sélectionnée" @click="selection" align-self="center"><i class="fa-solid fa-pen-to-square" id="modifOrgC"></i></a>
      </b-col>
      <b-col cols="1" class="icon"><a v-if="idOrganisme !== null" class="icon" title="supprimer l'organisme sélectionnée" v-on:click = "deleteOrganisme"
                                      align-self="center"><i class="fa-solid fa-trash-can" id="deleteOrgC"></i></a>
        <a v-else class="icon" title="supprimer l'organisme sélectionnée" v-on:click = "selection"
           align-self="center"><i class="fa-solid fa-trash-can" id="deleteOrgC"></i></a>
      </b-col>
      <b-col cols="3" class="icon">
        <b-form-group label-for="filter-input" label-align-sm="right" label-size="sm">
          <b-input-group size="sm">
            <b-form-input id="filter-input-orgC" v-model="filtre_organismes" type="search" placeholder="Rechercher Organisme"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filtre_organismes" @click="filtre_organismes = ''"><i class="fa-solid fa-arrows-rotate"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <div class="pagination">
          <p>{{nbResult}} organisme(s) sur {{rows}}</p>
        </div>
        <div class="pagination">
          <b-pagination class="customPagination"
                        v-model="currentPage"
                        :total-rows="nbResult !== 0 ? nbResult : rows"
                        :per-page="perPage"
                        aria-controls="my-card"
                        size="sm"
                        align="right"
                        id="paginationOrgC">
          </b-pagination>
        </div>
      </b-col>
    </b-row>

    <div id="my-modal6" size="lg" v-if="showModalAdd">
      <ModalAdd
          @close="showModalAdd = false">
      </ModalAdd>
    </div>
    <div id="my-modal7" size="lg" v-if="showModalModif">
      <ModalModif :id="idOrganisme" @close="showModalModif = false">
      </ModalModif>
    </div>

    <div v-if="organismesApp.length!==0" class="organismesApp mt-3">
      <b-card class="card-list">
        <p style="color: white"><strong><u>Liste des Organismes concepteur de l'application séléctionnée:</u></strong></p>
        <OrganismeApp
            :organismesApp="organismesApp"
            @deleteOrganisme="handleDeleteOrgC">
        </OrganismeApp>
      </b-card>
      <div class="bouton">
        <b-button size="sm" type="submit" v-on:click="insertOrgsCFromApp(organismesApp)" class="btn-ajout" id="addOrgCToApp">Enregistrer la séléction</b-button>
        <b-button size="sm" class="btn-ajout" @click="clearTable">Réinitialiser</b-button>
      </div>
    </div>

  </div>
</template>
<script>

import ModalAdd from "@/components/Applications/Organisme/ModalAjouterOrganisme.vue";
import ModalModif from "@/components/Applications/Organisme/ModalModifierOrganisme.vue";
import http from '@/http.js';
import eventBus from "@/eventBus";
import OrganismeApp from "@/components/Applications/Organisme/OrganismesAppList.vue";

export default {
  name: "OrganismesList",
  components: {OrganismeApp, ModalAdd, ModalModif},

  props:{
    organismes:{
      type:Array,
      required:true
    },
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data(){
    return{
      organisme:{},
      organismesApp:[],
      perPage: 10,
      currentPage: 1,
      selectMode: 'multi',
      showModalAdd:false,
      showModalModif:false,
      idOrganisme: null,
      filtre_organismes:'',
      nbResult:0,
      localOrgs: [],

      fields: [
        {key: 'selection', label:'Sélection', class: 'text-center'},
        {key: 'idOrganisme', label:'Id', class: 'text-center'},
        {key: 'sigle', label:'Sigle', class: 'text-center'},
        {key: 'nom', label:'Nom', class: 'text-center'},
        {key: 'noDept', label:'Département', class: 'text-center'},
        {key: 'ville', label:'Ville', class: 'text-center'}],
      items: [
        { key: "idOrganisme", label: "Id", sortable: true, tdClass: "text-left", thClass: "text-left" },
        { key: "sigle", label: "Sigle", label_long: "Sigle:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "nom", label: "Nom", label_long: "Nom:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "noDept", label: "Département", label_long: "Département:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "ville", label: "Ville", label_long: "Ville:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
      ],
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted(){
    eventBus.$on('updateApplicationSelected',(idApplication)=>{
      this.getOrganismeCApp(idApplication);
      this.idApplication = idApplication;
    })
  },

  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    updateOrganismes() {
      if (!this.filtre_organismes) {
        this.nbResult = this.organismes.length;
        return;
      }
      const filteredOrganismes = this.organismes.filter(organisme =>
          (organisme.sigle && organisme.sigle.toLowerCase().includes(this.filtre_organismes.toLowerCase())) ||
          (organisme.noDept && organisme.noDept.toString().includes(this.filtre_organismes)) ||
          (organisme.nom && organisme.nom.toLowerCase().includes(this.filtre_organismes.toLowerCase())) ||
          (organisme.ville && organisme.ville.toLowerCase().includes(this.filtre_organismes.toLowerCase()))
      );
      this.localOrgs = filteredOrganismes;
      this.nbResult = filteredOrganismes.length;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    handleDeleteOrgC(organisme) {
      this.organismesApp = this.organismesApp.filter(o => o.idOrganisme !== organisme.idOrganisme);
      this.$nextTick(() => {
        this.clearSelected();
      });
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    selection(){
      this.$bvToast.toast( `Veuillez sélectionner un organisme`, {
        variant: "danger",
        autoHideDelay: 5000,
        toaster: "b-toaster-top-center",
        noCloseButton: true,
        solid: true
      });
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getOrganismeCApp(idApplication){
      http.getOrganismesConcepteurFromApp(idApplication).then((data)=>{
        this.organismesApp = data;
      });
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    insertOrgsCFromApp(organismesApp){
      if(this.idApplication !== undefined){
        http.deleteOrganismesConcepteurFromApp(this.idApplication).then(() => {
          //
        }).then(() => {
          return Promise.all(organismesApp.map(org => http.insertOrganismesConcepteurFromApp(this.idApplication, org.idOrganisme)));
        }).then(() => {
          this.clearSelected();
          this.$bvToast.toast(`Organisme(s) ajouté(s) à l'application avec succès`, {
            variant: "success",
            autoHideDelay: 5000,
            toaster: "b-toaster-top-center",
            noCloseButton: true,
            solid: true
          });
        })
      } else {
        this.$bvToast.toast( ` Veuillez sélectionner une application`, {
          variant: "danger",
          autoHideDelay: 5000,
          toaster: "b-toaster-top-center",
          noCloseButton: true,
          solid: true
        });
      }
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    clearTable(){
      this.organismesApp=[];
      this.clearSelected();
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    onRowSelected(item) {
      const index = this.organismesApp.findIndex((org) => org.idOrganisme === item.idOrganisme);
      if (index !== -1) {
        this.organismesApp.splice(index, 1);
        if (this.organismesApp.length === 0) {
          this.idOrganisme = null;
        } else if (this.idOrganisme === item.idOrganisme) {
          this.idOrganisme = this.organismesApp[this.organismesApp.length - 1].idOrganisme;
        }
      } else {
        this.idOrganisme = item.idOrganisme;
        const doublon = this.organismesApp.some((organisme) => {
          return organisme.idOrganisme === item.idOrganisme
        })
        if (!doublon) {
          this.organismesApp.push(item);
        }
      }
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    deleteOrganisme(){
      http.verifLAppOrgExist(this.idOrganisme).then((exists)=>{
        if(exists.lAppOrgC || exists.lAppOrgD){
          // L'organisme existe dans une table lAppOrg, afficher un message d'erreur
          this.$bvToast.toast(`Impossible de supprimer l'organisme ${this.idOrganisme} car il est associé à des applications.`, {
            variant: "danger",
            autoHideDelay: 5000,
            toaster: "b-toaster-top-center",
            noCloseButton: true,
            solid: true
          });
        }else{
          if(confirm(`Voulez vous vraiment supprimer l'organisme : ${this.idOrganisme}`))
            http.deleteOrganisme(this.idOrganisme).then((data)=>{
              this.idOrganisme = data;
              this.organismesApp=[];
            }).then(() => {
              eventBus.$emit("reloadOrganismes");
            }).finally(()=> {
                this.$bvToast.toast(`Organisme supprimé avec succès`, {
                  variant: "success",
                  autoHideDelay: 5000,
                  toaster: "b-toaster-top-center",
                  noCloseButton: true,
                  solid: true
                })
              })
          }
      })
    }
  },

  watch: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    filtre_organismes(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1;
        this.updateOrganismes();
      }
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    rows() {
      return this.organismes.length
    }
  },

}
</script>
<style scoped>

.organismesApp{
  text-align: center;
  justify-content: center;
}

</style>