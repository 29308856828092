<template>
  <transition name="modal">
    <b-card class="modal-container">
      <ValidationObserver ref="observeradddonnees">
        <form @submit.prevent="emitInsertDonnees" id="validDonnee">
          <div class="titreModal pt-2 pb-2">
            <h5>Ajouter un type de données</h5>
          </div>
          <div>
            <b-card class=" formModal mr-4 ml-4">
              <ValidationProvider name="Type" rules="required" v-slot="{errors}">
                <b-form-group class="labelTitre" id="input-group-1" label="Type de données*:" label-size="sm" label-for="input-horizontal">
                  <b-form-input id="input-horizontal" v-model="type" type="text" size="sm">
                  </b-form-input>
                  <span style="color: red">{{errors[0]}}</span>
                </b-form-group>
              </ValidationProvider>
              <b-form-group class="labelTitre" id="input-group-2" label="Sous Type:" label-size="sm" label-for="input-2">
                <b-form-input id="input-2" v-model="sousType" type="text" size="sm">
                </b-form-input>
              </b-form-group>
              <b-form-group class="labelTitre" id="input-group-3" label="Source données:" label-size="sm" label-for="input-3">
                <b-form-input id="input-3" v-model="source" type="text" size="sm">
                </b-form-input>
              </b-form-group>
              <b-form-group class="labelTitre" id="input-group-4" label="Echelle géographique:" label-size="sm" label-for="input-4">
                <b-form-input id="input-4" v-model="echelleGeo" type="text" size="sm">
                </b-form-input>
              </b-form-group>
              <b-form-group class="labelTitre" id="input-group-5" label="Echelle temporelle:" label-size="sm" label-for="input-5">
                <b-form-input id="input-5" v-model="echelleTemp" type="text" size="sm">
                </b-form-input>
              </b-form-group>
              <b-form-group class="labelTitre"  label="Données sol:" label-size="sm" label-for="checkbox-2">
                <b-form-checkbox id="checkbox-2" v-model="donneeSol">
                </b-form-checkbox>
              </b-form-group>
              <b-form-group class="labelTitre" id="input-group-6" label="Type valeur:" label-size="sm" label-for="input-6">
                <b-form-select id="input-6" size="sm" v-model="typeValeur" :options="options">
                </b-form-select>
              </b-form-group>
            </b-card>
          </div>
          <div class="bouton">
            <b-button pill type="submit" class="valid m-2">Valider</b-button>
            <b-button pill class="closed m-2" @click="$emit('close')">Annuler</b-button>
          </div>

        </form>
      </ValidationObserver>
    </b-card>
  </transition>
</template>

<script>
import http from '@/http.js'
import eventBus from '@/eventBus.js'
import {extend} from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Ce champ est obligatoire'
});

export default {
  name:"ModalAddDonnees",


  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  destroyed(){
    eventBus.$off('insertDonnees');
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data(){
    return {
      locale:"fr-FR",
      idDonnee:'',
      type:'',
      sousType : '',
      donneeSol: false,
      source : '',
      echelleGeo : '',
      echelleTemp : '',
      typeValeur : '',
      options: [
        {value: 'Quantitative', text: 'Quantitative'},
        {value: 'Qualitative', text: 'Qualitative'}
      ],
      donneesData: Object,

    };
  },

  methods:{

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async emitInsertDonnees(){
      const isValid = await this.$refs.observeradddonnees.validate();
      if(isValid){
        eventBus.$emit("insertDonnees");
      } else{
        this.invalid = true;
      }
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted(){

    eventBus.$on('insertDonnees', () =>{
      this.donneesData = {
        idDonnee : this.idDonnee,
        type : this.type,
        sousType : this.sousType,
        donneeSol: this.donneeSol,
        source : this.source,
        echelleGeo : this.echelleGeo,
        echelleTemp : this.echelleTemp,
        typeValeur : this.typeValeur

      };
      http.insertDonnees(this.donneesData).then((data) => {
        this.idDonnee = data
      }).then(() => {
        this.$emit('close');
        eventBus.$emit("reloadDonnees");
      }).finally(()=>{
        this.$bvToast.toast( `Le type données a été ajouté avec succès`,{
          variant: "success",
          autoHideDelay: 5000,
          toaster: "b-toaster-top-center",
          noCloseButton: true,
          solid: true
        });
      })
    });
  }
}


</script>

<style>

</style>