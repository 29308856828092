<template>
  <transition name="modal">
    <b-card>
      <div class="titreModal pt-2 pb-2">
        <h5>Application sélectionnée</h5>
      </div>

      <div>
        <b-card class="formModal mr-4 ml-4">
          <form class="afficher">
            <b-form-group class="labelTitre" id="input-group-1" label-size="sm" label="Titre:" label-for="input-horizontal">
              <b-form-input id="input-horizontal" v-model="titre" size="sm" type="text" :readonly="lectureSeule">
              </b-form-input>
            </b-form-group>
            <b-row>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-2" label-size="sm" label="Année:" label-for="input-2">
                  <b-form-input id="input-2" size="sm" v-model="annee" type="text" :readonly="lectureSeule">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group class="labelTitre" label-size="sm" label="Détail méthode:" label-for="checkbox-1">
                  <b-form-checkbox id="checkbox-1" v-model="detailMethode" :disabled ="lectureSeule">
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group class="labelTitre" label-size="sm" label="Application validée:" label-for="checkbox-2">
                  <b-form-checkbox id="checkbox-2" v-model="validee" :disabled ="lectureSeule">
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-3" label-size="sm" label="N° Etude Donesol:" label-for="input-3">
                  <b-form-input id="input-3" size="sm" v-model="noEtude" type="text" :readonly="lectureSeule">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-4" label-size="sm" label="Résolution:" label-for="input-4">
                  <b-form-select v-if="resolution !== null" id="input-4" v-model="resolution.code" :disabled ="lectureSeule">
                    <option v-for="r in resolutions" v-bind:value="r.code" v-bind:key="r.code">{{r.signification}}</option>
                  </b-form-select>
                  <b-form-select v-else id="input-4" :disabled="lectureSeule">
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group class="labelTitre" id="input-group-5" label-size="sm" label="Etendue:" label-for="input-6">
              <b-form-select v-if="etendue !== null" id="input-4" v-model="etendue.code" :disabled ="lectureSeule">
                <option v-for="e in etendues" v-bind:value="e.code" v-bind:key="e.code">{{e.signification}}</option>
              </b-form-select>
              <b-form-select v-else id="input-4" :disabled="lectureSeule">
              </b-form-select>
            </b-form-group>
            <b-row>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-7" label-size="sm" label="Nature de l'étendue:" label-for="input-7">
                  <b-form-input id="input-7" size="sm" v-model="surface" type="text" :readonly="lectureSeule">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group class="labelTitre" id="input-group-8" label-size="sm" label="Finalité:" label-for="input-8">
              <b-form-select v-if="finalite !== null" id="input-8" v-model="finalite.code" :disabled ="lectureSeule">
                <option v-for="f in finalites" v-bind:value="f.code" v-bind:key="f.code">{{f.signification}}</option>
              </b-form-select>
              <b-form-select v-else id="input-4" :disabled="lectureSeule">
              </b-form-select>
            </b-form-group>
          </form>
        </b-card>
      </div>
      <div class="bouton">
        <b-button pill class="closed m-2" @click="$emit('close')">Annuler</b-button>
      </div>


    </b-card>
  </transition>
</template>

<script>

import http from "@/http";
import eventBus from '@/eventBus.js'

export default {
  name: 'ModifApplication',

  props:{
    id:{
      type:Number
    },
    lectureSeule:{
      type:Boolean,
      default:true
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  destroyed(){
    eventBus.$off('modifierApplication');
    eventBus.$off('updateApplicationSelected', this.updateModalData);
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data(){
    return {
      locale:"fr-FR",

      idApplication:'',
      annee:'',
      titre : '',
      detailMethode:'',
      noEtude : '',
      finalites:[],
      finalite:'',
      resolutions: [],
      resolution : '',
      surface : '',
      validee : '',
      etendues: [],
      etendue:'',
      applicationData: Object,
    };
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted() {
    eventBus.$on('updateApplicationSelected', this.updateModalData);
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  created(){
    http.getEtendues().then((data)=>{
      this.etendues = data;
    });
    http.getFinalites().then((data)=>{
      this.finalites = data;
    });
    http.getResolutions().then((data)=>{
      this.resolutions = data;
    });

    if(this.id !== undefined){
      http.loadApplication(this.id).then((data)=>{

        this.titre = data.titre;
        this.annee = data.annee;
        this.detailMethode = data.detailMethode;
        this.noEtude = data.noEtude;
        this.finalite = data.finalite;
        this.resolution = data.resolution;
        this.etendue = data.etendue;
        this.surface = data.surface;
        this.validee = data.validee;
      })

    }
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    updateModalData(idApplication) {

      http.loadApplication(idApplication).then((data) => {
        this.titre = data.titre;
        this.annee = data.annee;
        this.detailMethode = data.detailMethode;
        this.noEtude = data.noEtude;
        this.finalite = data.finalite;
        this.resolution = data.resolution;
        this.etendue = data.etendue;
        this.surface = data.surface;
        this.validee = data.validee;
      })
    },
  },

}
</script>

<style scoped>

.afficher{
  margin-top: -1em;
}

</style>