<template>
  <div class="refBiblioList">
    <div v-if="showRefs">
      <p style="padding-top: 10px"><strong>III. Références bibliographiques de la méthode de traitement</strong></p>
      <div class="tableElement">
        <b-table sticky-header
               striped bordered small selectable
               :items="refsBiblios"
               :fields="fields"
               primary-key="idRef"
               :select-mode="selectMode"
               :per-page="perPage"
               :current-page="currentPage"
               :filter="filtre_refBiblios"
               :filter-included-fields="['titre','annee','document']"
               ref="selectableTable"
               @row-clicked="onRowSelected"
               id="tableRef">

          <template #cell(selection)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
            </template>
          </template>
          <template #cell(idRef)="data">
            {{data.item.idRef}}
          </template>
        </b-table>
      </div>
      <b-row>
        <b-col cols="1" class="icon">
          <a class="icon" title="ajouter une nouvelle référence" v-b-modal.modal-lg ="'my-modal17'"
          @click.prevent ="showModalAdd = !showModalAdd; showModalModif=false" align-self="center"><i class="fas fa-plus-circle" id="ajoutRef"></i></a>
        </b-col>
        <b-col cols="1" class="icon">
          <a class="icon" v-if="idRef!==null" title="modifier la référence sélectionnée" v-b-modal.modal-lg ="'my-modal18'"
             @click.prevent ="showModalModif = !showModalModif; showModalAdd=false" align-self="center"><i class="fa-solid fa-pen-to-square" id="modifRef"></i></a>
          <a class="icon" v-else title="modifier la référence sélectionnée" @click="selection"
             align-self="center"><i class="fa-solid fa-pen-to-square" id="modifRef"></i></a>
        </b-col>
        <b-col cols="1" class="icon">
          <a v-if="idRef!==null" class="icon" title="supprimer la référence sélectionnée"
             v-on:click = "deleteRef" align-self="center"><i class="fa-solid fa-trash-can" id="deleteRef"></i></a>
          <a v-else class="icon" title="supprimer la référence sélectionnée" v-on:click = "selection"
             align-self="center"><i class="fa-solid fa-trash-can" id="deleteRef"></i></a>
        </b-col>
        <b-col cols="3" class="icon">
          <b-form-group label-for="filter-input" label-align-sm="right" label-size="sm">
            <b-input-group size="sm">
              <b-form-input id="filter-input" v-model="filtre_refBiblios" type="search" placeholder="Rechercher Référence"></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filtre_refBiblios" @click="filtre_refBiblios = ''"><i class="fa-solid fa-arrows-rotate"></i></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <div class="pagination">
            <p>{{nbResult}} référence(s) sur {{rows}}</p>
          </div>
          <div class="pagination">
            <b-pagination class="customPagination"
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          aria-controls="my-card"
                          size="sm"
                          align="right"
                          id="paginationRef">
            </b-pagination>
          </div>
        </b-col>
      </b-row>

      <div id="my-modal17" size="xl" v-if="showModalAdd">
        <ModalAdd
            @close="showModalAdd = false">
        </ModalAdd>
      </div>
      <div id="my-modal18" size="xl" v-if="showModalModif">
        <ModalModif
            :id="idRef"
            @close="showModalModif = false">
        </ModalModif>
      </div>
    </div>
    <div v-if="refsMethode.length!==0" class="refsMethode mt-3">
      <b-card class="card-list">
        <p style="color: white"><strong><u>Liste des Références de l'étape séléctionnée: {{etape.noEtape}} {{etape.nom}}</u></strong></p>
        <RefsMethode
            :refsMethode="refsMethode"
            @deleteRef="handleDeleteRef">
        </RefsMethode>
      </b-card>
      <div v-if="showRefs" class="bouton">
        <b-button size="sm" v-on:click="insertRefFromEtape(refsMethode)" class="btn-ajout" id="addRefFromEtape">Enregistrer la séléction</b-button>
        <b-button size="sm" class="btn-ajout" @click="clearTable">Réinitialiser</b-button>
        <b-button size="sm" @click="showModal" class="btn-ajout">revenir aux données</b-button>
      </div>
    </div>
  </div>
</template>
<script>

import ModalAdd from "@/components/Applications/RefBiblio/ModalAjouterRef.vue";
import ModalModif from "@/components/Applications/RefBiblio/ModalModifierRef.vue";
import http from "@/http";
import eventBus from "@/eventBus";
import RefsMethode from "@/components/Methode/Etape/Ref/RefBiblioMethodeList.vue";


export default {
  name: "ReferenceBiblio",
  components: {ModalModif, ModalAdd, RefsMethode},

  props:{
    refsBiblios:{
      type:Array,
      required:true
    },
    idEtape:{
      type:Number,
      required: true
    },
    showModal:Function
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data(){
    return{
      refBiblio:{},
      refsMethode:[],
      idEtp: this.idEtape,
      perPage: 10,
      currentPage: 1,
      selectMode: 'multi',
      showModalAdd:false,
      showModalModif:false,
      idRef: null,
      filtre_refBiblios:"",
      showRefs:true,
      etape:{},
      localRefs: [],
      nbResult:0,

      fields: [
        {key: 'selection', label:'Sélection', class: 'text-center'},
        {key: 'idRef', label:'Id', class: 'text-center'},
        {key: 'titre', label:'Titre', class: 'text-center'},
        {key: 'type', label:'Type réf', class: 'text-center'},
        {key: 'document', label:'Doc. source', class: 'text-center'},
        {key: 'annee', label:'Année publi.', class: 'text-center'}],
      items: [
        { key: "idRef", label: "Id", sortable: true, tdClass: "text-left", thClass: "text-left" },
        { key: "titre", label: "Titre", label_long: "Titre:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "type", label: "Type réf", label_long: "Type réf:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "document", label: "Doc. source", label_long: "Doc. source:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "annee", label: "Année publi.", label_long: "Année publi.:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },

      ],
    }
  },
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted(){
      this.getRefsEtape();
      if(this.idEtp !==0){
        this.loadEtape();
      }
  },

  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    updateRefs() {
      if (!this.filtre_refBiblios) {
        this.nbResult = this.refsBiblios.length;
        return;
      }
      const filteredRefs = this.refsBiblios.filter(ref =>
          (ref.titre && ref.titre.toLowerCase().includes(this.filtre_refBiblios.toLowerCase())) ||
          (ref.document && ref.document.toLowerCase().includes(this.filtre_refBiblios.toLowerCase())) ||
          (ref.annee && ref.annee.toString().includes(this.filtre_refBiblios))
      );

      this.localRefs = filteredRefs;
      this.nbResult = filteredRefs.length;
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    handleDeleteRef(ref) {
      this.refsMethode = this.refsMethode.filter(r => r.idRef !== ref.idRef);
      this.$nextTick(() => {
        this.clearSelected();
      });
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getRefsEtape(){
      http.getRefsFromEtape(this.idEtape).then((data)=>{
        this.refsMethode = data;
      })
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    loadEtape(){
      http.loadEtape(this.idEtp).then((data)=>{
        this.etape = data;
      })
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    selection(){
      this.$bvToast.toast( `Veuillez sélectionner une référence`, {
        variant: "danger",
        autoHideDelay: 5000,
        toaster: "b-toaster-top-center",
        noCloseButton: true,
        solid: true
      });
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    clearTable(){
      this.refsMethode=[];
      this.clearSelected();
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    onRowSelected(item) {
      const index = this.refsMethode.findIndex((ref) => ref.idRef === item.idRef);
      if (index !== -1) {
        this.refsMethode.splice(index, 1);
        if (this.refsMethode.length === 0) {
          this.idRef = null;
        } else if (this.idRef === item.idRef) {
          this.idRef = this.refsMethode[this.refsMethode.length - 1].idRef;
        }
      } else {
        eventBus.$emit('updateRefSelected', item.idRef);
        this.idRef = item.idRef;
        const doublon = this.refsMethode.some((ref) => {
          return ref.idRef === item.idRef
        })
        if (!doublon) {
          this.refsMethode.push(item);
        }
      }
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    deleteRef() {
      http.verifLMethTraitRefExist(this.idRef).then((exists) => {
        if (exists) {
          this.$bvToast.toast(`Impossible de supprimer la référence ${this.idRef} car elle est associée à des methodes.`, {
            variant: "danger",
            autoHideDelay: 5000,
            toaster: "b-toaster-top-center",
            noCloseButton: true,
            solid: true
          });
        } else {
          if (confirm(`Voulez vous vraiment supprimer la réference : ${this.idRef}`))
            http.deleteRef(this.idRef).then((data) => {
              this.idRef = data;
            }).then(() => {
              eventBus.$emit("reloadRefs");
            }).finally(() => {
              this.$bvToast.toast(`Référence bibliographique ${this.idRef} supprimée avec succès`, {
                variant: "success",
                autoHideDelay: 5000,
                toaster: "b-toaster-top-center",
                noCloseButton: true,
                solid: true
              });
            });
        }
      });
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    insertRefFromEtape(refsMethode) {
      if (this.idEtp !== undefined) {
        http.deleteRefsFromEtape(this.idEtp).then((data)=>{
          this.idEtp = data;
        }).then(()=>{
          return Promise.all(refsMethode.map(ref => http.insertRefsFromEtape(this.idEtape, ref.idRef)));
        }).then(()=>{
          this.showRefs = false;
          this.$bvToast.toast(`Références bobliographique ajoutée(s) à l'étape avec succès`, {
            variant: "success",
            autoHideDelay: 5000,
            toaster: "b-toaster-top-center",
            noCloseButton: true,
            solid: true
          });
        })
      }else {
        this.$bvToast.toast(` Veuillez saisir une étape`, {
          variant: "danger",
          autoHideDelay: 5000,
          toaster: "b-toaster-top-center",
          noCloseButton: true,
          solid: true
        });
      }
    }
  },

  watch: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    filtre_refBiblios(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1;
        this.updateRefs();
      }
    },
  },

  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    rows() {
      return this.refsBiblios.length
    }
  },

}
</script>
<style scoped>

.refsMethode{
  text-align: center;
  justify-content: center;
}

</style>