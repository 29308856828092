<template>
  <div class="applications">
    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="card p-1" role="tab">
          <b-button block v-b-toggle.accordion-1 class="app">Sélection d'une application existante</b-button>
        </b-card-header>
          <b-card-body id="accordion-1">
            <ApplicationMethodeList
                :applications="applications">
            </ApplicationMethodeList>
          </b-card-body>
      </b-card>
    </div>
  </div>
</template>
<script>
import http from '@/http.js'
import ApplicationMethodeList from "@/components/Methode/Application/ApplicationMethodeList.vue";

export default {
  name: "ApplicationsMethodeList",
  components: { ApplicationMethodeList},


  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      applications:[],
      applicationData: {},
    };
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted(){
    this.getApplicationsMethode();
  },

  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getApplicationsMethode(){
      http.getApplicationsMethode().then((data) => {
        this.applications = data;
      });
    },
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  created(){
    this.getApplicationsMethode();
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    find() {
      const page =
          this.currentPage > 1 ? (this.currentPage - 1) * this.perPage : 0;
      return this.applications.slice(page, page + this.perPage);
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    rows() {
      return this.applications.length
    }
  },

}
</script>
<style>
#accordion-1 {
  background-color: aliceblue;
}
.applications{
  margin-left: 4em;
  margin-right: 4em;
  background-color: aliceblue;
}
.app{
  background-color: #66c1bf !important;
}
</style>