<template>
  <transition name="modal">
    <b-card class="modal-container">
      <ValidationObserver ref="observeraddmethode">
        <div class="mr-4 ml-4 mb-3">
          <b-row>
            <b-col cols="3">
              <b-form-group class="labelTitre" id="input-group-1" style="text-align: start" label-size="sm" label="ID Meth:" label-for="input-1">
                <b-form-input v-if="idMethode!==0" id="input-1" style="width: 60%" v-model="idMethode" :readonly="lectureSeule" size="sm" type="text">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <form @submit.prevent="emitInsertMethode" id="saveMethode">
          <div>
            <b-card class="formModal mr-4 ml-4">
              <ValidationProvider name="Titre" rules="required" v-slot="{errors}">
                <b-form-group class="labelTitre" id="input-group-2" label-size="sm" label="Libellé de la méthode*:" label-for="textarea">
                  <b-form-textarea
                      id="textarea"
                      v-model="libelleMethode"
                      rows="3"
                      max-rows="6">
                  </b-form-textarea>
                  <span style="color: red">{{errors[0]}}</span>
                </b-form-group>
              </ValidationProvider>
              <b-row>
                <b-col cols="6">
                  <b-form-group class="labelTitre" id="input-group-3" label-size="sm" label="Résolution:" label-for="input-3">
                    <b-form-select id="input-3" size="sm" v-model="resolution.code">
                      <option :value="null"></option>
                      <option v-for="r in resolutions" v-bind:value="r.code" v-bind:key="r.code">{{r.signification}}</option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group class="labelTitre" id="input-group-4" label-size="sm" label="Facilite:" label-for="input-4">
                <b-form-select id="input-4" size="sm" v-model="facilite.code">
                  <option :value="null"></option>
                  <option v-for="f in facilites" v-bind:value="f.code" v-bind:key="f.code">{{f.signification}}</option>
                </b-form-select>
              </b-form-group>

              <b-form-group class="labelTitre" id="input-group-5" label-size="sm" label="Validation:" label-for="input-5">
                <b-form-select id="input-5" size="sm" v-model="validation.code">
                  <option :value="null"></option>
                  <option v-for="v in validations" v-bind:value="v.code" v-bind:key="v.code">{{v.signification}}</option>
                </b-form-select>
              </b-form-group>

              <b-row>
                <b-col cols="6">
                  <b-form-group class="labelTitre" label-size="sm" label="Combinaison méthodes traitements (o/n):" label-for="checkbox-1">
                    <b-form-checkbox id="checkbox-1" v-model="combineMethTrait">
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group class="labelTitre" label-size="sm" label="Résultats spatialisés (o/n):" label-for="checkbox-2">
                    <b-form-checkbox id="checkbox-2" v-model="spatialisationMethode">
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col sm="5">
                  <label class="labelTitre" for="textarea-small">Sélectionnez le ou les outils nécessaires à la méthode:</label>
                </b-col>
                <b-col sm="5">
                <b-form-group class="labelTitre" id="input-group-6" label-size="sm" label-for="input-6">
                  <b-form-select id="input-6" size="sm" v-model="outilsToAdd" multiple :select-size="4">
                    <option :value="null"></option>
                    <option v-for="o in outils" v-bind:value="o" v-bind:key="o.code">{{o.signification}}</option>
                  </b-form-select>
                </b-form-group>
                </b-col>
              </b-row>

            </b-card>
          </div>
          <div class="bouton">
            <b-button pill type="submit" v-if="idMethode!==0" class="valid m-2">Modifier la méthode</b-button>
            <b-button pill type="submit" v-else class="valid m-2">Enregistrer la méthode</b-button>
            <b-button pill type="reset" @click="reset" class="valid m-2">Réinitialiser les champs</b-button>
          </div>

        </form>
      </ValidationObserver>
    </b-card>
  </transition>
</template>

<script>

import http from "@/http";
import eventBus from '@/eventBus.js'
export default {
  name: 'SelectMethode',

  props:{
    idApplicationMeth:{
      type:Number
    },
    lectureSeule:{
      type:Boolean,
      default:true
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  destroyed(){
    eventBus.$off('insertMethode');
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data(){
    return {
      locale:"fr-FR",
      idMethode:0,
      combineMethTrait: false,
      spatialisationMethode : false,
      facilites:[],
      facilite:{},
      resolutions: [],
      resolution : {},
      validations: [],
      validation:{},
      outils:[],
      outilsToAdd:[],
      outil:{},
      libelleMethode:'',
      methodeData: Object,
      lMethOutilData : Object,
      lMethOutilList: [],
      application:{}
    };
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  created(){
    http.getValidations().then((data)=>{
      this.validations = data;
    });
    http.getFacilites().then((data)=>{
      this.facilites = data;
    });
    http.getResolutionsMethode().then((data)=>{
      this.resolutions = data;
    });
    http.getOutils().then((data)=>{
      this.outils = data;
    });
    this.getMethode(this.idApplicationMeth);
  },

  methods:{

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    reset(){
      this.validation = http.getValidations().then((data)=>{
        this.validations = data;
      });
      this.facilite = http.getFacilites().then((data)=>{
        this.facilites = data;
      });
      this.resolution = http.getResolutionsMethode().then((data)=>{
        this.resolutions = data;
      });
      this.outil = http.getOutils().then((data)=>{
        this.outils = data;
      });
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getMethode(idApplicationMeth){
      if(idApplicationMeth !== null){
        http.loadApplication(idApplicationMeth).then((data)=>{
          this.application = data;
        }).then(()=>{
          if(this.application.idMethode !== null){
            http.loadMethode(idApplicationMeth).then((data)=>{
              this.idMethode = data.idMethode;
              this.combineMethTrait = data.combineMethTrait;
              this.spatialisationMethode = data.spatialisationMethode;
              if(data.faciliteMethode !== null){
                this.facilite = data.faciliteMethode;
              }
              if(data.resolutionMethode !== null){
                this.resolution = data.resolutionMethode;
              }
              if(data.validationMethode!==null){
                this.validation = data.validationMethode;
              }
              this.libelleMethode = data.libelleMethode;
              this.outils = data.outilsMethode;
            })
          }
        })
      }
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async emitInsertMethode(){
      if(this.idMethode === 0){
        if (this.idApplicationMeth !== null) {
          const isValid = await this.$refs.observeraddmethode.validate();
          if (isValid) {
            eventBus.$emit("insertMethode");
          } else {
            this.invalid = true;
          }
        }else{
          this.$bvToast.toast( `Veuillez selectionner une application`, {
            variant: "danger",
            autoHideDelay: 5000,
            toaster: "b-toaster-top-center",
            noCloseButton: true,
            solid: true
          });
        }
      }else{
        eventBus.$emit("modifierMethode");
      }
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted(){

    eventBus.$on('updateApplicationMethSelected',(idApplication)=>{
      this.getMethode(idApplication);
    })

    eventBus.$on('insertMethode', () =>{
      this.methodeData = {
        idMethode : this.idMethode,
        libelleMethode : this.libelleMethode,
        combineMethTrait : this.combineMethTrait,
        spatialisationMethode: this.spatialisationMethode,
        faciliteMethode : this.facilite,
        resolutionMethode : this.resolution,
        validationMethode : this.validation,
        outilsMethode: this.outilsToAdd,
        idApplication: this.idApplicationMeth,
      };
        http.insertMethode(this.methodeData).then((data) => {
          this.idMethode = data
        }).then(() => {
          this.$emit('close');
        }).finally(()=>{
          this.$bvToast.toast( `La méthode a été ajoutée avec succès`,{
            variant: "success",
            autoHideDelay: 5000,
            toaster: "b-toaster-top-center",
            noCloseButton: true,
            solid: true
          });
        })
    });

    eventBus.$on('modifierMethode', () =>{
      this.methodeData = {
        idMethode : this.idMethode,
        libelleMethode : this.libelleMethode,
        combineMethTrait : this.combineMethTrait,
        spatialisationMethode: this.spatialisationMethode,
        faciliteMethode : this.facilite,
        resolutionMethode : this.resolution,
        validationMethode : this.validation,
        outilsMethode: this.outilsToAdd,
        idApplication: this.idApplicationMeth,
      };
      http.updateMethode(this.methodeData).then((data) => {
        this.idMethode = data
      }).then(() => {
        this.$emit('close');
      }).finally(()=>{
        this.$bvToast.toast( `La méthode a été modifiée avec succès`,{
          variant: "success",
          autoHideDelay: 5000,
          toaster: "b-toaster-top-center",
          noCloseButton: true,
          solid: true
        });
      })
    });
  }
}
</script>

<style scoped>

.modal-container {
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;

}

.labelTitre{
  padding-top: 5px;
  font-size: 14px;
}

</style>