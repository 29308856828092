<template>
  <div class="applicationList">
    <div>
      <p class="idapp" v-if="idApplication != null">ID app: <strong>{{idApplication}}</strong></p>
    </div>
    <div class="tableElement">
      <b-table sticky-header
               striped bordered small selectable
               :items="applications"
               :fields="fields"
               primary-key="idApplication"
               :select-mode="selectMode"
               :per-page="perPage"
               :current-page="currentPage"
               :filter="filtre_applications"
               :filter-included-fields="['titre','annee']"
               ref="selectableTable"
               @row-clicked="onRowSelected"
               id="tableApp">

        <template #cell(selection)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
          </template>
        </template>
        <template #cell(idApplication)="data">
          {{data.item.idApplication}}
        </template>
        <template #cell(detailMethode)="data">
          <b-form-checkbox v-model="data.value" disabled></b-form-checkbox>
        </template>
        <template #cell(validee)="data">
          <b-form-checkbox v-model="data.value" disabled></b-form-checkbox>
        </template>
      </b-table>
    </div>
    <b-row>
      <b-col cols="1" class="icon"><a class="icon" title="ajouter une nouvelle application" v-b-modal.modal-lg ="'ajout'"
        @click.prevent ="showModalAdd = !showModalAdd; showModalAfficher=false;showModalModif=false" align-self="center"><i class="fas fa-plus-circle" id="ajoutApp"></i></a>
      </b-col>
      <b-col cols="1" class="icon"><a class="icon" v-if="idApplication!==null" title="voir l'application sélectionnée" v-b-modal ="'my-modal3'"
       @click.prevent ="showModalAfficher =!showModalAfficher; showModalModif=false; showModalAdd=false" align-self="center"><i class="fa-solid fa-eye" id="showApp"></i></a>
        <a class="icon" v-else title="voir l'application sélectionnée" @click="selection" align-self="center"><i class="fa-solid fa-eye" id="showApp"></i></a>
      </b-col>
      <b-col cols="1" class="icon"><a class="icon" v-if="idApplication!==null" title="modifier l'application sélectionnée" v-b-modal.modal-lg ="'my-modal2'"
        @click.prevent ="showModalModif = !showModalModif;showModalAdd=false;showModalAfficher=false " align-self="center"><i class="fa-solid fa-pen-to-square" id="modifApp"></i></a>
        <a class="icon" v-else title="voir l'application sélectionnée" @click="selection" align-self="center"><i class="fa-solid fa-pen-to-square" id="modifApp"></i></a>
      </b-col>
      <b-col cols="1" class="icon"><a v-if="idApplication!==null" class="icon" title="supprimer l'application sélectionnée" v-on:click = "deleteApp"
                                      align-self="center"><i class="fa-solid fa-trash-can" id="deleteApp"></i></a>
        <a v-else class="icon" title="supprimer l'application sélectionnée" v-on:click = "selection"
           align-self="center"><i class="fa-solid fa-trash-can" id="deleteApp"></i></a>
      </b-col>
      <b-col cols="3" class="icon">
        <b-form-group label-for="filter-input" label-align-sm="right" label-size="sm">
          <b-input-group size="sm">
            <b-form-input id="filter-input-app" v-model="filtre_applications" type="search" placeholder="Rechercher Application"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filtre_applications" @click="filtre_applications = ''"><i class="fa-solid fa-arrows-rotate"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <div class="pagination">
          <p>{{nbResult}} application(s) sur {{rows}}</p>
        </div>
        <div class="pagination">
          <b-pagination class="customPagination"
                        v-model="currentPage"
                        :total-rows="nbResult !== 0 ? nbResult : rows"
                        :per-page="perPage"
                        aria-controls="my-card"
                        size="sm"
                        align="right"
                        id="paginationApp">
          </b-pagination>
        </div>
      </b-col>
    </b-row>
    <div id="my-modal3">
      <form>
        <ModalAfficher :id="idApplication" :lectureSeule="lectureSeule" v-if="showModalAfficher" @close="showModalAfficher = false" class="center"></ModalAfficher>
      </form>
    </div>

    <div id="ajout" size="xl" v-if="showModalAdd">
      <ModalAdd
          @close="showModalAdd = false">
      </ModalAdd>
    </div>
    <div id="my-modal2" size="xl" v-if="showModalModif">
      <ModalModif :id="idApplication" @close="showModalModif = false">
      </ModalModif>
    </div>
  </div>
</template>
<script>
import eventBus from "@/eventBus";
import ModalAdd from "@/components/Applications/Application/ModalAjouterApplication.vue"
import ModalModif from "@/components/Applications/Application/ModalModifierApplication.vue"
import ModalAfficher from "@/components/Applications/Application/ModalAfficherApplication.vue"
import http from "@/http";
export default {
  name: "ApplicationsList",

  components:{
    ModalAdd,
    ModalModif,
    ModalAfficher

  },

  props:{
    applications:{
      type:Array,
      required:true
    },

    lectureSeule:{
      type:Boolean,
      default:true
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data(){
    return{
      application:{},
      perPage: 10,
      currentPage: 1,
      selectMode: 'single',
      showModalAdd:false,
      showModalModif:false,
      showModalAfficher:false,
      idApplication: null,
      filtre_applications:"",
      nbResult:0,
      localApplications: [],

      fields: [
        {key: 'selection', label:'Sélection', class: 'text-center'},
        {key: 'idApplication', label:'Id', class: 'text-center'},
        {key: 'titre', label:'Titre', class: 'text-center'},
        {key: 'annee', label:'Année', class: 'text-center'},
        {key: 'detailMethode', label:'Détail méthode', class: 'text-center'},
        {key: 'validee', label:'App.Validée', class: 'text-center'}],
      items: [
        { key: "idApplication", label: "Id", sortable: true, tdClass: "text-left", thClass: "text-left" },
        { key: "titre", label: "Titre", label_long: "Titre:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "annee", label: "Année", label_long: "Année:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "detailMethode", label: "Détail méthode", label_long: "Détail méthode:", sortable: true, tdClass: "text-left", thClass: "text-left", type:"boolean", required: true },
        { key: "validee", label: "App.Validée", label_long: "App.Validée:", sortable: true, tdClass: "text-left", thClass: "text-left", type:"boolean", required: true },

      ],
    }
  },

  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    updateApplications() {
      if (!this.filtre_applications) {
        this.nbResult = this.applications.length;
        return;
      }
      const filteredApplications = this.applications.filter(application =>
          (application.titre && application.titre.toLowerCase().includes(this.filtre_applications.toLowerCase())) ||
          (application.annee && application.annee.toString().includes(this.filtre_applications))
      );
      this.localApplications = filteredApplications;
      this.nbResult = filteredApplications.length;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    onRowSelected(item) {
      eventBus.$emit('updateApplicationSelected',item.idApplication);
      this.idApplication = item.idApplication;
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    selection(){
      this.$bvToast.toast( `Veuillez sélectionner une application`, {
        variant: "danger",
        autoHideDelay: 5000,
        toaster: "b-toaster-top-center",
        noCloseButton: true,
        solid: true
      });
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    deleteApp() {
      if (confirm(`Voulez-vous vraiment supprimer l'application : ${this.idApplication}`)) {
        http.deleteApplication(this.idApplication).then(() => {
          this.idApplication = null;
        }).then(() => {
          eventBus.$emit("reloadApplications");
        }).finally(() => {
          this.$bvToast.toast(`Application supprimée avec succès`, {
            variant: "success",
            autoHideDelay: 5000,
            toaster: "b-toaster-top-center",
            noCloseButton: true,
            solid: true
          });
        });
      }
    }
  },

  watch: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    filtre_applications(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1;
        this.updateApplications();
      }
    },
  },

  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    rows() {
      return this.applications.length
    },
  },

}
</script>
<style scoped>

.idapp{
  text-align: end;
}

</style>