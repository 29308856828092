<template>
  <transition name="modal">
    <b-card class="modal-container">
      <ValidationObserver ref="observermodifapplication">
      <form @submit.prevent="emitModifierApplication" id="modifDonnees">
      <div class="titreModal pt-2 pb-2">
        <h5>Modifier l'application sélectionnée</h5>
      </div>

      <div>
        <b-card class=" formModal mr-4 ml-4">
          <ValidationProvider name="Titre" rules="required" v-slot="{errors}">
            <b-form-group class="labelTitre" id="input-group-1" label="Titre*:" label-size="sm" label-for="input-horizontal">
              <b-form-input id="input-horizontal" v-model="titre" type="text"
                            size="sm" placeholder="renseigner le titre de l'application">
              </b-form-input>
              <span style="color: red">{{errors[0]}}</span>
            </b-form-group>
          </ValidationProvider>
            <b-row>
              <b-col cols="6">
                <ValidationProvider name="Année" rules="required" v-slot="{errors}">
                <b-form-group class="labelTitre" id="input-group-2" label="Année*:" label-size="sm" label-for="input-2">
                  <b-form-input id="input-2" v-model="annee" type="text" size="sm">
                  </b-form-input>
                  <span style="color: red">{{errors[0]}}</span>
                </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-form-group class="labelTitre"  label="Détail méthode:" label-size="sm" label-for="checkbox-1">
              <b-form-checkbox id="checkbox-1" v-model="detailMethode">
              </b-form-checkbox>
            </b-form-group>
            <b-row>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-3" label="N° Etude Donesol:" label-size="sm" label-for="input-3">
                  <b-form-input id="input-3" v-model="noEtude" type="text" size="sm">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group class="labelTitre" id="input-group-4" label="Résolution:" label-size="sm" label-for="input-4">
              <b-form-select id="input-4" size="sm" v-model="resolution.code">
                <option v-for="r in resolutions" v-bind:value="r.code" v-bind:key="r.code">{{r.signification}}</option>
              </b-form-select>
            </b-form-group>

            <b-form-group class="labelTitre" id="input-group-5" label="Etendue:" label-size="sm" label-for="input-6">
              <b-form-select id="input-4" size="sm" v-model="etendue.code">
                <option v-for="e in etendues" v-bind:value="e.code" v-bind:key="e.code">{{e.signification}}</option>
              </b-form-select>
            </b-form-group>
            <b-row>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-7" label="Nature de l'étendue:" label-size="sm" label-for="input-7">
                  <b-form-input id="input-7" v-model="surface" type="text" size="sm">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group class="labelTitre" id="input-group-8" label="Finalité:" label-size="sm" label-for="input-8">
              <b-form-select id="input-8" size="sm" v-model="finalite.code">
                <option v-for="f in finalites" v-bind:value="f.code" v-bind:key="f.code">{{f.signification}}</option>
              </b-form-select>
            </b-form-group>
            <b-form-group class="labelTitre"  label="Application validée:" label-size="sm" label-for="checkbox-2">
              <b-form-checkbox id="checkbox-2" v-model="validee">
              </b-form-checkbox>
            </b-form-group>


        </b-card>
      </div>
      <div class="bouton">
        <b-button pill type="submit" class="valid m-2">Valider</b-button>
        <b-button pill class="closed m-2" @click="$emit('close')">Annuler</b-button>
      </div>
      </form>
      </ValidationObserver>
    </b-card>
  </transition>
</template>

<script>
  import http from "@/http";
  import eventBus from '@/eventBus.js'
  import {extend} from 'vee-validate'
  import { required } from 'vee-validate/dist/rules'

  extend('required', {
    ...required,
    message: 'Ce champ est obligatoire'
  });

  export default {
    name: 'ModifApplication',

    props:{
      id:{
        type:Number
      },
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data(){
      return {
        locale:"fr-FR",

        idApplication:'',
        identifiantApplication: this.id,
        annee:'',
        titre : '',
        detailMethode:'',
        noEtude : '',
        finalites:[],
        finalite:{},
        resolutions: [],
        resolution : {},
        surface : '',
        validee : '',
        etendues: [],
        etendue:{},
        invalid:false,
        applicationData: Object,
      };
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    created(){
      http.getEtendues().then((data)=>{
        this.etendues = data;
      });
      http.getFinalites().then((data)=>{
        this.finalites = data;
      });
      http.getResolutions().then((data)=>{
        this.resolutions = data;
      });

      if(this.id !== undefined){
        http.loadApplication(this.id).then((data)=>{
          this.idApplication = data.idApplication;
          this.titre = data.titre;
          this.annee = data.annee;
          this.detailMethode = data.detailMethode;
          this.noEtude = data.noEtude;
          if (data.finalite !== null && data.finalite !== undefined) {
            this.finalite = data.finalite;
          }
          if (data.resolution !== null && data.resolution !== undefined) {
            this.resolution = data.resolution;
          }
          if (data.etendue !== null && data.etendue !== undefined) {
            this.etendue = data.etendue;
          }
          this.surface = data.surface;
          this.validee = data.validee;
        })

      }
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    methods:{

      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      async emitModifierApplication(){
        const isValid = await this.$refs.observermodifapplication.validate();
        if(isValid){
          eventBus.$emit("modifierApplication");
        }else{
          this.invalid = true;
        }

      }
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    mounted(){

      eventBus.$on('modifierApplication', () =>{
        this.applicationData = {
          idApplication : this.idApplication,
          titre : this.titre,
          annee : this.annee,
          detailMethode : this.detailMethode,
          noEtude : this.noEtude,
          finalite : this.finalite,
          resolution : this.resolution,
          etendue : this.etendue,
          surface : this.surface,
          validee : this.validee,
        };
        http.updateApplication(this.applicationData).then((data) => {
          this.identifiantApplication = data
        }).then(() => {
          this.$emit('close');
          eventBus.$emit("reloadApplications");
        }).catch(() => {
          this.$bvToast.toast("Erreur lors de la mise à jour de l'application", {
            variant: "danger",
            autoHideDelay: 5000,
            toaster: "b-toaster-top-center",
            noCloseButton: true,
            solid: true
          });
        }).finally(()=>{
          this.$bvToast.toast( `L'application ${this.applicationData.idApplication} a été modifiée avec succès`,{
            variant: "success",
            autoHideDelay: 5000,
            toaster: "b-toaster-top-center",
            noCloseButton: true,
            solid: true
          });
        })
      });
    }
  }

</script>

<style>

</style>