<template>
  <transition name="modal">
    <b-card class="modal-container">
      <ValidationObserver ref="observeraddref">
      <form @submit.prevent="emitInsertRef" id="validRef">
        <div class="titreModal pt-2 pb-2">
          <h5>Ajouter une Réference</h5>
        </div>

        <div>
          <b-card class=" formModal mr-4 ml-4">
            <ValidationProvider name="Titre réf" rules="required" v-slot="{errors}">
            <b-form-group class="labelTitre" id="input-group-1" label-size="sm" label="Titre réf*:" label-for="input-horizontal">
              <b-form-input id="input-1" v-model="titre" type="text" size="sm">
              </b-form-input>
              <span style="color: red">{{errors[0]}}</span>
            </b-form-group>
            </ValidationProvider>
            <b-row>
              <b-col cols="8">
                <ValidationProvider name="Type réf" rules="required" v-slot="{errors}">
                <b-form-group class="labelTitre" id="input-group-2" label-size="sm" label="Type réf*:" label-for="input-2">
                  <b-form-select id="input-2" size="sm" v-model="typeRef.code" >
                    <option v-for="t in typeRefs" v-bind:value="t.code" v-bind:key="t.code">{{t.signification}}</option>
                  </b-form-select>
                  <span style="color: red">{{errors[0]}}</span>
                </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-form-group class="labelTitre" id="input-group-2" label-size="sm" label="Document source:" label-for="input-2">
              <b-form-input id="input-3" v-model="document" type="text" size="sm">
              </b-form-input>
            </b-form-group>
            <b-row>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-3" label-size="sm" label="Pagination:" label-for="input-3">
                  <b-form-input id="input-4" v-model="pagination" type="text" size="sm">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <ValidationProvider name="Année publication" rules="required" v-slot="{errors}">
                <b-form-group class="labelTitre" id="input-group-4" label-size="sm" label="Année publication*:" label-for="input-4">
                  <b-form-input id="input-5" v-model="annee" type="text" size="sm">
                  </b-form-input>
                  <span style="color: red">{{errors[0]}}</span>
                </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-form-group class="labelTitre" id="input-group-5" label-size="sm" label="Mots clés:" label-for="input-6">
              <b-form-input id="input-6" v-model="motCle" type="text" size="sm">
              </b-form-input>
            </b-form-group>
            <b-row>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-6" label-size="sm" label="N° Identification:" label-for="input-7">
                  <b-form-input id="input-7" v-model="identification" type="text" size="sm">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-7" label-size="sm" label="Disponibilité:" label-for="input-8">
                  <b-form-input id="input-8" v-model="disponibilite" type="text" size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-button block v-b-toggle.accordion-9 class="listAuteurs mt-3">Affecter des auteurs</b-button>
            <b-collapse id="accordion-9" role="tabpanel">
              <b-card-body>
                <AuteursList
                    :auteursRef="auteursToAdd"
                    :auteurs="auteurs"
                    :AuteursFromRef="AuteursToInsert">
                </AuteursList>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div class="bouton">
          <b-button pill type="submit" class="valid m-2">Valider</b-button>
          <b-button pill class="closed m-2" @click="$emit('close')">Annuler</b-button>
        </div>
      </form>
      </ValidationObserver>
    </b-card>
  </transition>
</template>

<script>
import http from '@/http.js'
import eventBus from '@/eventBus.js'
import {extend} from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import AuteursList from "@/components/Applications/RefBiblio/AuteursListFromRef.vue";

extend('required', {
  ...required,
  message: 'Ce champ est obligatoire'
});

export default {
  name:"ModalAddRef",
  components: {AuteursList},


  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  destroyed(){
    eventBus.$off('insertRef');
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data(){
    return {
      locale:"fr-FR",

      idRef:0,
      typeRef:{},
      typeRefs:[],
      auteurs:[],
      auteursToAdd:[],
      auteurData: {},
      titre : '',
      document:'',
      pagination : '',
      annee:'',
      motCle:'',
      identification : '',
      disponibilite : '',
      invalid:false,
      refBiblioData: Object,

    };
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  created(){
    http.getTypeRefs().then((data)=>{
      this.typeRefs = data;
    })

    http.getAuteurs().then((data)=>{
      this.auteurs = data;
    })
  },

  methods:{

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    AuteursToInsert(auteursToAdd){
      this.auteursToAdd = auteursToAdd;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async emitInsertRef(){
      const isValid = await this.$refs.observeraddref.validate();
      if (isValid){
        eventBus.$emit("insertRef");
      }else{
        this.invalid = true;
      }

    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted(){

    eventBus.$on('insertRef', () =>{
      this.refBiblioData = {
        idRef : this.idRef,
        typeRef : this.typeRef.code,
        titre : this.titre,
        document: this.document,
        pagination : this.pagination,
        annee : this.annee,
        motCle : this.motCle,
        identification : this.identification,
        disponibilite : this.disponibilite,
        auteurs : this.auteursToAdd,

      };
      http.insertRef(this.refBiblioData).then((data) => {
        this.idRef = data
      }).then(() => {
        this.$emit('close');
        eventBus.$emit("reloadRefs");
      }).finally(()=>{
        this.$bvToast.toast( `La référence bibliographique a été ajoutée avec succès`,{
          variant: "success",
          autoHideDelay: 5000,
          toaster: "b-toaster-top-center",
          noCloseButton: true,
          solid: true
        });
      })
    });
  }



}


</script>

<style>

</style>