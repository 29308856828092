<template>
  <transition name="modal">
    <b-card class="modal-container">
      <form @submit.prevent="emitModifierRef" id="modifReference">
        <div class="titreModal pt-2 pb-2">
          <h5>Modifier la réference séléctionnée</h5>
        </div>

        <div>
          <b-card class=" formModal mr-4 ml-4">

            <b-form-group class="labelTitre" id="input-group-1" label-size="sm" label="Titre réf:" label-for="input-horizontal">
              <b-form-input id="input-1" v-model="titre" type="text" size="sm">
              </b-form-input>
            </b-form-group>
            <b-row>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-2" label-size="sm" label="Type réf:" label-for="input-2">
                  <b-form-select id="input-2" v-model="typeRef.code" >
                    <option v-for="t in typeRefs" v-bind:value="t.code" v-bind:key="t.code">{{t.signification}}</option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group class="labelTitre" id="input-group-2" label-size="sm" label="Document source:" label-for="input-2">
              <b-form-input id="input-2" v-model="document" type="text" size="sm">
              </b-form-input>
            </b-form-group>
            <b-row>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-3" label-size="sm" label="Pagination:" label-for="input-3">
                  <b-form-input id="input-3" v-model="pagination" type="text" size="sm">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-4" label-size="sm" label="Année publication:" label-for="input-4">
                  <b-form-input id="input-4" v-model="annee" type="text" size="sm">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group class="labelTitre" id="input-group-5" label-size="sm" label="Mots clés:" label-for="input-6">
              <b-form-input id="input-5" v-model="motCle" type="text" size="sm">
              </b-form-input>
            </b-form-group>
            <b-row>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-6" label-size="sm" label="N° Identification:" label-for="input-7">
                  <b-form-input id="input-6" v-model="identification" type="text" size="sm">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-7" label-size="sm" label="Disponibilité:" label-for="input-8">
                  <b-form-input id="input-7" v-model="disponibilite" type="text" size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-button block v-b-toggle.accordion-10 class="listAuteurs mt-3">Affecter des auteurs</b-button>
            <b-collapse id="accordion-10" role="tabpanel">
              <b-card-body>
                <AuteursList
                    :auteurs="auteurs"
                    :AuteursFromRef="AuteursToInsert"
                    :auteursRef="auteursRef">
                </AuteursList>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div class="bouton">
          <b-button pill type="submit" class="valid m-2">Valider</b-button>
          <b-button pill class="closed m-2" @click="$emit('close')" id="close">Annuler</b-button>
        </div>
      </form>
    </b-card>
  </transition>
</template>
<script>

import http from "@/http";
import eventBus from '@/eventBus.js'
import AuteursList from "@/components/Applications/RefBiblio/AuteursListFromRef.vue";

export default {
  name: 'ModifRef',
  components: {AuteursList},

  props:{
    id:{
      type: Number
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  destroyed(){
    eventBus.$off('modifierRef');
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data(){
    return {
      locale:"fr-FR",
      identifiantRef: this.id,
      idRef:'',
      auteurs:[],
      auteursRef:[],
      auteursToAdd:[],
      typeRef:{},
      typeRefs:[],
      titre : '',
      document:'',
      pagination : '',
      annee:'',
      motCle:'',
      identification : '',
      disponibilite : '',
      refBiblioData: Object,
    };
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  created(){
    http.getTypeRefs().then((data)=>{
      this.typeRefs = data;
    });

    http.getAuteurs().then((data)=>{
      this.auteurs = data;
    })

    if(this.id !== undefined){
      http.getAuteursFromRef(this.id).then((data)=>{
        this.auteursRef = data;
      });
    }

    if(this.id !== undefined){
      http.loadRef(this.id).then((data)=>{
        this.idRef = data.idRef;
        this.typeRef = data.typeRef;
        this.titre = data.titre;
        this.document = data.document;
        this.pagination = data.pagination;
        this.annee = data.annee;
        this.motCle = data.motCle;
        this.identification = data.identification;
        this.disponibilite = data.disponibilite;
        this.auteursRef = data.auteurs;
      })
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  methods:{

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async emitModifierRef(){
      eventBus.$emit("modifierRef");
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    AuteursToInsert(auteursRef){
      this.auteursToAdd = auteursRef;
    },
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted(){

    eventBus.$on('modifierRef', () =>{
      this.refBiblioData = {
        idRef : this.idRef,
        typeRef : this.typeRef,
        titre : this.titre,
        document : this.document,
        pagination : this.pagination,
        annee : this.annee,
        motCle : this.motCle,
        identification : this.identification,
        disponibilite : this.disponibilite,
        auteurs : this.auteursToAdd,
      };
      http.updateRef(this.refBiblioData).then((data) => {
        this.identifiantRef = data
      }).then(() => {
        this.$emit('close');
        eventBus.$emit("reloadRefs");
      }).finally(()=>{
        this.$bvToast.toast( `La référence bibliographique' ${this.refBiblioData.idRef} a été modifiée avec succès`,{
          variant: "success",
          autoHideDelay: 5000,
          toaster: "b-toaster-top-center",
          noCloseButton: true,
          solid: true
        });
      })
    });
  }

}

</script>

<style>

</style>