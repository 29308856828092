<template>
  <div class="auteurList">
    <div class="tableElement">
      <b-table sticky-header
        striped bordered small selectable
        :items="auteurs"
        :fields="fields"
        primary-key="idAuteur"
        :select-mode="selectMode"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filtre_auteurs"
        :filter-included-fields="['nom','prenom','email','organisme']"
        ref="selectableTable"
        @row-clicked="onRowSelected"
        id="tableAuteur">

        <template #cell(selection)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
          </template>
        </template>
        <template #cell(idAuteur)="data">
          {{data.item.idAuteur}}
        </template>
      </b-table>
    </div>
    <b-row>
      <b-col cols="1" class="icon"><a class="icon" title="ajouter un nouvel auteur" v-b-modal.modal-lg ="'my-modal4'"
        @click.prevent ="showModalAdd = !showModalAdd; showModalModif=false" align-self="center"><i class="fas fa-plus-circle" id="ajoutAuteur"></i></a>
      </b-col>
      <b-col cols="1" class="icon"><a class="icon" v-if="idAuteur!==null" title="modifier l'auteur sélectionné" v-b-modal.modal-lg ="'my-modal5'"
        @click.prevent ="showModalModif = !showModalModif;showModalAdd=false" align-self="center"><i class="fa-solid fa-pen-to-square" id="modifAuteur"></i></a>
        <a class="icon" v-else title="modifier l'auteur sélectionné" @click="selection" align-self="center"><i class="fa-solid fa-pen-to-square" id="modifAuteur"></i></a>
      </b-col>
      <b-col cols="1" class="icon"><a v-if="idAuteur!==null" class="icon" title="supprimer l'auteur sélectionné" v-on:click = "deleteAuteur"
        align-self="center"><i class="fa-solid fa-trash-can" id="deleteAuteur"></i></a>
        <a v-else class="icon" title="supprimer l'auteur sélectionné" v-on:click = "selection"
           align-self="center"><i class="fa-solid fa-trash-can" id="deleteAuteur"></i></a>
      </b-col>
      <b-col cols="3" class="icon">
        <b-form-group label-for="filter-input" label-align-sm="right" label-size="sm">
          <b-input-group size="sm">
            <b-form-input id="filter-input-auteur" v-model="filtre_auteurs" type="search" placeholder="Rechercher Auteur"></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filtre_auteurs" @click="filtre_auteurs = ''"><i class="fa-solid fa-arrows-rotate"></i></b-button>
              </b-input-group-append>
            </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <div class="pagination">
          <p>{{nbResult}} auteurs(s) sur {{rows}}</p>
        </div>
        <div class="pagination">
          <b-pagination class="customPagination"
                        v-model="currentPage"
                        :total-rows="nbResult !== 0 ? nbResult : rows"
                        :per-page="perPage"
                        aria-controls="my-card"
                        size="sm"
                        align="right"
                        id="paginationAuteur">
          </b-pagination>
        </div>
      </b-col>
    </b-row>

    <div id="my-modal4" size="xl" v-if="showModalAdd">
      <ModalAdd
          @close="showModalAdd = false">
      </ModalAdd>
    </div>
    <div id="my-modal5" size="xl" v-if="showModalModif">
      <ModalModif :id="idAuteur" @close="showModalModif = false">
      </ModalModif>
    </div>

    <div v-if="auteursApp.length!==0" class="auteursApp mt-3">
      <b-card class="card-list">
        <p style="color: white"><strong><u>Liste des Auteurs de l'application séléctionnée:</u></strong></p>
        <AuteurApp
            :auteursApp="auteursApp"
            @deleteAuteur="handleDeleteAuteur">
        </AuteurApp>
      </b-card>
      <div class="bouton">
        <b-button size="sm" type="submit" v-on:click="insertAuteursFromApp(auteursApp)" class="btn-ajout" id="addAuteursApp">Enregistrer la séléction</b-button>
        <b-button size="sm" class="btn-ajout" @click="clearTable">Réinitialiser</b-button>
      </div>
    </div>

  </div>
</template>
<script>

import AuteurApp from "@/components/Applications/Auteur/AuteurListFrom.vue";
import ModalAdd from "@/components/Applications/Auteur/ModalAjouterAuteur.vue"
import ModalModif from "@/components/Applications/Auteur/ModalModifierAuteur.vue"
import http from "@/http";
import eventBus from '@/eventBus.js'

export default {
  name: "AuteursList",
  components: {AuteurApp, ModalModif, ModalAdd},

  props:{
    auteurs:{
      type:Array,
      required:true
    },

    lectureSeule:{
      type:Boolean,
      default:true
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data(){
    return{
      auteur:{},
      auteursApp:[],
      perPage: 10,
      currentPage: 1,
      selectMode: 'multi',
      showModalAdd:false,
      showModalModif:false,
      idAuteur: null,
      filtre_auteurs:"",
      localAuteurs: [],
      nbResult:0,

      fields: [
        {key: 'selection', label:'Sélection', class: 'text-center'},
        {key: 'idAuteur', label:'Id', class: 'text-center'},
        {key: 'nom', label:'Nom', class: 'text-center'},
        {key: 'prenom', label:'Prénom', class: 'text-center'},
        {key: 'email', label:'Mail', class: 'text-center'},
        {key: 'organisme', label:'Organisme', class: 'text-center'}],
      items: [
        { key: "idAuteur", label: "idAuteur", sortable: true, tdClass: "text-left", thClass: "text-left" },
        { key: "nom", label: "Nom", label_long: "Nom:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "prenom", label: "Prénom", label_long: "Prénom:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "email", label: "mail", label_long: "Email:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },
        { key: "organisme", label: "organisme", label_long: "Organisme:", sortable: true, tdClass: "text-left", thClass: "text-left", type: "text", required: true },

      ],
    }
  },


  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted(){
    eventBus.$on('updateApplicationSelected',(idApplication)=>{
      this.getAuteursApp(idApplication);
      this.idApplication = idApplication;
    })
  },

  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    updateAuteurs() {
      if (!this.filtre_auteurs) {
        this.nbResult = this.auteurs.length;
        return;
      }
      const filteredAuteurs = this.auteurs.filter(auteur =>
          (auteur.nom && auteur.nom.toLowerCase().includes(this.filtre_auteurs.toLowerCase())) ||
          (auteur.prenom && auteur.prenom.toLowerCase().includes(this.filtre_auteurs.toLowerCase())) ||
          (auteur.email && auteur.email.toLowerCase().includes(this.filtre_auteurs.toLowerCase())) ||
          (auteur.organisme && auteur.organisme.toLowerCase().includes(this.filtre_auteurs.toLowerCase()))
      );

      this.localAuteurs = filteredAuteurs;
      this.nbResult = filteredAuteurs.length;
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    handleDeleteAuteur(auteur) {
      this.auteursApp = this.auteursApp.filter(a => a.idAuteur !== auteur.idAuteur);
      this.$nextTick(() => {
        this.clearSelected();
      });
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    selection(){
      this.$bvToast.toast( `Veuillez sélectionner un auteur`, {
        variant: "danger",
        autoHideDelay: 5000,
        toaster: "b-toaster-top-center",
        noCloseButton: true,
        solid: true
      });
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getAuteursApp(idApplication){
      http.getAuteursFromApp(idApplication).then((data) => {
        this.auteursApp = data;
      });
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    insertAuteursFromApp(auteursApp){
      if(this.idApplication !== undefined){
        http.deleteAuteurFromApp(this.idApplication).then(()=>{
          //
        }).then(()=>{
          return Promise.all(auteursApp.map(auteur => http.insertAuteursFromApp(this.idApplication, auteur.idAuteur)));
            }).then(() => {
              this.clearSelected();
          this.$bvToast.toast(`Auteur(s) ajouté(s) à l'application avec succès`, {
            variant: "success",
            autoHideDelay: 5000,
            toaster: "b-toaster-top-center",
            noCloseButton: true,
            solid: true
            })
        })
      }else{
        this.$bvToast.toast( ` Veuillez selectionner une application`, {
          variant: "danger",
          autoHideDelay: 5000,
          toaster: "b-toaster-top-center",
          noCloseButton: true,
          solid: true
        });
      }
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    clearTable(){
      this.auteursApp=[];
      this.clearSelected();
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    onRowSelected(item) {
      const index = this.auteursApp.findIndex((auteur) => auteur.idAuteur === item.idAuteur);
      if (index !== -1) {
        // L'auteur est déjà sélectionné, on le déselectionne
        this.auteursApp.splice(index, 1);
        // Si aucun autre auteur n'est sélectionné, réinitialisation idAuteur à null
        if (this.auteursApp.length === 0) {
          this.idAuteur = null;
        } else if (this.idAuteur === item.idAuteur) {
          // mise à jour idAuteur avec le dernier auteur restant dans la liste auteursApp
          this.idAuteur = this.auteursApp[this.auteursApp.length - 1].idAuteur;
        }
      } else {
        // L'auteur n'est pas déjà sélectionné, on le sélectionne
        eventBus.$emit('updateAuteurSelected', item.idAuteur);
        this.idAuteur = item.idAuteur;
        const doublon = this.auteursApp.some((auteur) => {
          return auteur.idAuteur === item.idAuteur;
        });
        if (!doublon) {
          this.auteursApp.push(item);
        }
      }
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    deleteAuteur() {
      http.verifLAppSourceExist(this.idAuteur).then((exists) => {
        if (exists) {
          // L'auteur existe dans la table lAppSource, afficher un message d'erreur
          this.$bvToast.toast(`Impossible de supprimer l'auteur ${this.idAuteur} car il est associé à des applications.`, {
            variant: "danger",
            autoHideDelay: 5000,
            toaster: "b-toaster-top-center",
            noCloseButton: true,
            solid: true
          });
        } else {
          // L'auteur n'existe pas dans la table lAppSource, on peut le supprimer
          if (confirm(`Voulez-vous vraiment supprimer l'auteur : ${this.idAuteur}`)) {
            http.deleteAuteur(this.idAuteur).then(() => {
              this.idAuteur = null;
              this.auteursApp = [];
            }).then(() => {
              eventBus.$emit("reloadAuteurs");
            }).finally(() => {
              this.$bvToast.toast(`Auteur supprimé avec succès`, {
                variant: "success",
                autoHideDelay: 5000,
                toaster: "b-toaster-top-center",
                noCloseButton: true,
                solid: true
              });
            });
          }
        }
      });
    }
  },

  watch: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    filtre_auteurs(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1;
        this.updateAuteurs();
      }
    },
  },

  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    rows() {
      return this.auteurs.length
    },
  },

}
</script>
<style scoped>

.auteursApp{
 text-align: center;
  justify-content: center;
}
</style>