<template>
  <div class="typeDonneesList">
    <div class="tableSecondElement">
      <b-table sticky-header
               striped bordered small
               :items="donneesEtape"
               :fields="fields"
               primary-key="idDonnee">

        <template #cell(idDonnee)="data">
          {{data.item.idDonnee}}
        </template>
        <template #cell(actions)="data">
          <a @click="supprimerDonnees(data.item)" class="icon_delete" title="supprimer la donnée"><i class="fa-solid fa-trash-can"></i></a>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>

export default {
  name: "DonneesList",

  props:{
    donneesEtape:{
      type:Array,
      required:true
    },

    lectureSeule:{
      type:Boolean,
      default:true
    }
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data(){
    return{

      fields: [
        {key: 'idDonnee', label:'Id', class: 'text-center'},
        {key: 'type', label:'Type de donnée', class: 'text-center'},
        {key: 'sousType', label:'Sous Type', class: 'text-center'},
        {key: 'echelleGeo', label:'Echelle géographique', class: 'text-center'},
        {key: 'echelleTemp', label:'Echelle temporelle', class: 'text-center'},
        {key: 'donneeSol', label:'Donnée sol', class: 'text-center'},
        { key: "actions", label: "Actions", class: "text-center" },],
    }
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    supprimerDonnees(donnee) {
      this.$emit("deleteDonnee", donnee);
    },
  },
}
</script>
<style scoped>

</style>